import type { AdminOrganizationRolesListResponseDto } from 'dtos';
import useSWR from 'swr';
import { fetcher } from '../utils/client/swr/jsonFetcher';

type TransformedOrganizationRole = {
  value: string;
  label: string;
};

type UseOrganizationRolesReturn = {
  organizationRoles: TransformedOrganizationRole[];
  isLoading: boolean;
  error: Error | undefined;
  refresh: () => void;
};

const useOrganizationRoles = (): UseOrganizationRolesReturn => {
  const key = '/roles';

  const {
    data,
    error,
    isValidating: isLoading,
    mutate,
  } = useSWR<AdminOrganizationRolesListResponseDto, Error>(key, fetcher, {
    revalidateOnFocus: false,
  });

  const organizationRoles =
    data?.data.map((role) => ({
      value: role.role,
      label: role.role,
    })) || [];

  return {
    organizationRoles,
    isLoading,
    error,
    refresh: mutate,
  };
};

export default useOrganizationRoles;
