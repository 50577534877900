import { Box, Button, TextField } from '@mui/material';
import type { Context, HierarchicalAgency } from 'components/types';
import React, { useState } from 'react';
import {
  depthFirstSearch,
  getContextFromCookie,
} from 'utils/client/contextTree';

import type { ContextToken } from 'dtos';
import ContextAutoCompleteNode from './ContextAutoCompleteNode';

type ContextTreeProps = {
  hierarchy: HierarchicalAgency[];
  value: Context | null;
  setValue: (context: Context | null) => void;
};

export default function ContextAutoComplete({
  hierarchy,
  value,
  setValue,
}: ContextTreeProps) {
  const [query, setQuery] = useState('');

  const filteredHierarchy = depthFirstSearch([...hierarchy], query) || [];

  const content: ContextToken | null = getContextFromCookie();

  const defaultContext: Context | null = content?.isEmployeeContext
    ? // get first context for employees
      hierarchy?.map((ha) => ({
        agencyId: ha.id,
        userId: ha.users[0]?.id,
      }))?.[0] ?? null
    : null;

  return (
    <Box sx={{ p: 3 }}>
      <TextField
        onChange={({ currentTarget: { value } }) => setQuery(value)}
        fullWidth
        autoFocus
        autoComplete="off"
        placeholder="Search"
        sx={{ mb: 2 }}
      />

      {value && (
        <Button sx={{ ml: 'auto' }} onClick={() => setValue(defaultContext)}>
          Back to default view
        </Button>
      )}
      {filteredHierarchy.map((node) => (
        <ContextAutoCompleteNode
          onSelect={({ agencyId, userId }) => {
            setValue({ agencyId, userId });
          }}
          key={node.id}
          node={node}
          query={query}
        />
      ))}
    </Box>
  );
}
