import type { CurrencyDto } from 'dtos';

export const USD = 'USD';

const usdCurrency = {
  id: '1',
  code: 'USD',
  name: 'United States Dollar',
  symbol: '$',
};

export const currencies: Record<string, CurrencyDto> = {
  USD: usdCurrency,
  EUR: { id: '2', code: 'EUR', name: 'Euro', symbol: '€' },
  JPY: { id: '3', code: 'JPY', name: 'Japanese Yen', symbol: '¥' },
  GBP: { id: '4', code: 'GBP', name: 'British Pound Sterling', symbol: '£' },
  AUD: { id: '5', code: 'AUD', name: 'Australian Dollar', symbol: 'A$' },
  CAD: { id: '6', code: 'CAD', name: 'Canadian Dollar', symbol: 'C$' },
  CHF: { id: '7', code: 'CHF', name: 'Swiss Franc', symbol: 'CHF' },
  SEK: { id: '8', code: 'SEK', name: 'Swedish Krona', symbol: 'kr' },
  MXN: { id: '9', code: 'MXN', name: 'Mexican Peso', symbol: '$' },
  NZD: { id: '10', code: 'NZD', name: 'New Zealand Dollar', symbol: '$' },
  AED: { id: '11', code: 'AED', name: 'UAE Dirham', symbol: 'د.إ' },
  AFN: { id: '12', code: 'AFN', name: 'Afghan Afghani', symbol: '؋' },
  ALL: { id: '13', code: 'ALL', name: 'Albanian Lek', symbol: 'L' },
  AMD: { id: '14', code: 'AMD', name: 'Armenian Dram', symbol: '֏' },
  ANG: { id: '15', code: 'ANG', name: 'Dutch Guilder', symbol: 'ƒ' },
  AOA: { id: '16', code: 'AOA', name: 'Angolan Kwanza', symbol: 'Kz' },
  ARS: { id: '17', code: 'ARS', name: 'Argentine Peso', symbol: '$' },
  AWG: { id: '18', code: 'AWG', name: 'Aruban Florin', symbol: 'ƒ' },
  AZN: { id: '19', code: 'AZN', name: 'Azerbaijan Manat', symbol: '₼' },
  BAM: { id: '20', code: 'BAM', name: 'Bosnian Mark', symbol: 'KM' },
  BBD: { id: '21', code: 'BBD', name: 'Barbadian Dollar', symbol: 'Bds$' },
  BDT: { id: '22', code: 'BDT', name: 'Bangladeshi Taka', symbol: '৳' },
  BGN: { id: '23', code: 'BGN', name: 'Bulgarian Lev', symbol: 'лв' },
  BHD: { id: '24', code: 'BHD', name: 'Bahraini Dinar', symbol: '.د.ب' },
  BIF: { id: '25', code: 'BIF', name: 'Burundi Franc', symbol: 'FBu' },
  BMD: { id: '26', code: 'BMD', name: 'Bermuda Dollar', symbol: 'BD$' },
  BND: { id: '27', code: 'BND', name: 'Brunei Dollar', symbol: 'B$' },
  BOB: { id: '28', code: 'BOB', name: 'Bolivian Boliviano', symbol: 'Bs.' },
  BRL: { id: '29', code: 'BRL', name: 'Brazilian Real', symbol: 'R$' },
  BSD: { id: '30', code: 'BSD', name: 'Bahamian Dollar', symbol: 'B$' },
  BTC: { id: '31', code: 'BTC', name: 'Bitcoin', symbol: '₿' },
  BTN: { id: '32', code: 'BTN', name: 'Bhutanese Hgultrum', symbol: 'Nu.' },
  BWP: { id: '33', code: 'BWP', name: 'Botswana Pula', symbol: 'P' },
  BYN: { id: '34', code: 'BYN', name: 'Belarussian Ruble', symbol: 'Br' },
  BZD: { id: '35', code: 'BZD', name: 'Belizean Dollar', symbol: 'BZ$' },
  CDF: { id: '36', code: 'CDF', name: 'Congolese Franc', symbol: 'FC' },
  CLP: { id: '37', code: 'CLP', name: 'Chilean Peso', symbol: '$' },
  CNY: { id: '156', code: 'CNY', name: 'Chinese Yuan', symbol: '¥' },
  CRC: { id: '38', code: 'CRC', name: 'Costa Rica Colon', symbol: '₡' },
  CUP: { id: '39', code: 'CUP', name: 'Cuban Peso', symbol: '₱' },
  CVE: { id: '40', code: 'CVE', name: 'Cape Verde Escudo', symbol: 'Esc' },
  CZK: { id: '41', code: 'CZK', name: 'Czech Koruna', symbol: 'Kč' },
  DJF: { id: '42', code: 'DJF', name: 'Djibouti Franc', symbol: 'Fdj' },
  DKK: { id: '43', code: 'DKK', name: 'Danish Krone', symbol: 'kr' },
  DOP: { id: '44', code: 'DOP', name: 'Dominican Peso', symbol: 'RD$' },
  DZD: { id: '45', code: 'DZD', name: 'Algerian Dinar', symbol: 'دج' },
  EGP: { id: '46', code: 'EGP', name: 'Egyptian Pound', symbol: '£' },
  ERN: { id: '47', code: 'ERN', name: 'Eritrean Nakfa', symbol: 'Nfk' },
  ETB: { id: '48', code: 'ETB', name: 'Ethiopian Birr', symbol: 'Br' },
  ETH: { id: '49', code: 'ETH', name: 'Ethereum', symbol: 'Ξ' },
  FJD: { id: '50', code: 'FJD', name: 'Fiji Dollar', symbol: 'FJ$' },
  FKP: { id: '51', code: 'FKP', name: 'Falkland Islands Pound', symbol: '£' },
  GEL: { id: '52', code: 'GEL', name: 'Georgian Lari', symbol: '₾' },
  GHS: { id: '53', code: 'GHS', name: 'Ghanaian Cedi', symbol: 'GH₵' },
  GIP: { id: '54', code: 'GIP', name: 'Gibraltar Pound', symbol: '£' },
  GMD: { id: '55', code: 'GMD', name: 'Gambian Dalasi', symbol: 'D' },
  GNF: { id: '56', code: 'GNF', name: 'Guinea Franc', symbol: 'FG' },
  GTQ: { id: '57', code: 'GTQ', name: 'Guatemalan Quetzal', symbol: 'Q' },
  GYD: { id: '58', code: 'GYD', name: 'Guyana Dollar', symbol: 'GY$' },
  HKD: { id: '59', code: 'HKD', name: 'Hong Kong Dollar', symbol: 'HK$' },
  HNL: { id: '60', code: 'HNL', name: 'Honduran Lempira', symbol: 'L' },
  HRK: { id: '61', code: 'HRK', name: 'Croatian Kuna', symbol: 'kn' },
  HTG: { id: '62', code: 'HTG', name: 'Haiti Gourde', symbol: 'G' },
  HUF: { id: '63', code: 'HUF', name: 'Hungarian Forint', symbol: 'Ft' },
  IDR: { id: '64', code: 'IDR', name: 'Indonesian Rupiah', symbol: 'Rp' },
  ILS: { id: '65', code: 'ILS', name: 'Israeli Shekel', symbol: '₪' },
  INR: { id: '66', code: 'INR', name: 'Indian Rupee', symbol: '₹' },
  IQD: { id: '67', code: 'IQD', name: 'Iraqi Dinar', symbol: 'ع.د' },
  IRR: { id: '68', code: 'IRR', name: 'Iranian Rial', symbol: '﷼' },
  ISK: { id: '69', code: 'ISK', name: 'Iceland Krona', symbol: 'kr' },
  JMD: { id: '70', code: 'JMD', name: 'Jamaican Dollar', symbol: 'J$' },
  JOD: { id: '71', code: 'JOD', name: 'Jordanian Dinar', symbol: 'JD' },
  KES: { id: '72', code: 'KES', name: 'Kenyan Shilling', symbol: 'KSh' },
  KGS: { id: '73', code: 'KGS', name: 'Kyrgyzstani Som', symbol: 'с' },
  KHR: { id: '74', code: 'KHR', name: 'Cambodian Riel', symbol: '៛' },
  KMF: { id: '75', code: 'KMF', name: 'Comoro Franc', symbol: 'CF' },
  KPW: { id: '76', code: 'KPW', name: 'North Korean Won', symbol: '₩' },
  KRW: { id: '77', code: 'KRW', name: 'South Korean Won', symbol: '₩' },
  KTD: { id: '78', code: 'KTD', name: 'Cayman Islands Dollar', symbol: 'CI$' },
  KWD: { id: '79', code: 'KWD', name: 'Kuwaiti Dinar', symbol: 'KD' },
  KZT: { id: '80', code: 'KZT', name: 'Kazakhstan Tenge', symbol: '₸' },
  LAK: { id: '81', code: 'LAK', name: 'Lao Kip', symbol: '₭' },
  LBP: { id: '82', code: 'LBP', name: 'Lebanese Pound', symbol: 'ل.ل' },
  LKR: { id: '83', code: 'LKR', name: 'Sri Lankan Rupee', symbol: 'Rs' },
  LRD: { id: '84', code: 'LRD', name: 'Liberian Dollar', symbol: 'L$' },
  LSL: { id: '85', code: 'LSL', name: 'Lesotho Loti', symbol: 'L' },
  LTC: { id: '86', code: 'LTC', name: 'Litecoin', symbol: 'Ł' },
  LYD: { id: '87', code: 'LYD', name: 'Libyan Dinar', symbol: 'ل.د' },
  MAD: { id: '88', code: 'MAD', name: 'Moroccan Dirham', symbol: 'د.م.' },
  MDL: { id: '89', code: 'MDL', name: 'Moldovan Leu', symbol: 'L' },
  MGA: { id: '90', code: 'MGA', name: 'Malagasy Ariary', symbol: 'Ar' },
  MKD: { id: '91', code: 'MKD', name: 'Macedonian Denar', symbol: 'ден' },
  MMK: { id: '92', code: 'MMK', name: 'Myanmar Kyat', symbol: 'Ks' },
  MNT: { id: '93', code: 'MNT', name: 'Mongolian Tugrik', symbol: '₮' },
  MOP: { id: '94', code: 'MOP', name: 'Macanese Pataca', symbol: 'MOP$' },
  MRO: { id: '95', code: 'MRO', name: 'Mauritanian Ouguiya', symbol: 'UM' },
  MUR: { id: '96', code: 'MUR', name: 'Mauritius Rupee', symbol: '₨' },
  MVR: { id: '97', code: 'MVR', name: 'Maldives Rufiyaa', symbol: 'Rf' },
  MWK: { id: '98', code: 'MWK', name: 'Malawian Kwacha', symbol: 'MK' },
  MYR: { id: '99', code: 'MYR', name: 'Malaysian Ringgit', symbol: 'RM' },
  MZN: { id: '100', code: 'MZN', name: 'Mozambique Metical', symbol: 'MT' },
  NAD: { id: '101', code: 'NAD', name: 'Namibian Dollar', symbol: '$' },
  NGN: { id: '102', code: 'NGN', name: 'Nigerian Naira', symbol: '₦' },
  NIO: { id: '103', code: 'NIO', name: 'Nicaragua Cordoba', symbol: 'C$' },
  NOK: { id: '104', code: 'NOK', name: 'Norwegian Krone', symbol: 'kr' },
  NPR: { id: '105', code: 'NPR', name: 'Nepalese Rupee', symbol: '₨' },
  OMR: { id: '106', code: 'OMR', name: 'Omani Rial', symbol: 'ر.ع.' },
  PAB: { id: '107', code: 'PAB', name: 'Panama Balboa', symbol: 'B/.' },
  PEN: { id: '108', code: 'PEN', name: 'Peruvian Nuevo Sol', symbol: 'S/' },
  PGK: { id: '109', code: 'PGK', name: 'Papua New Guinean Kina', symbol: 'K' },
  PHP: { id: '110', code: 'PHP', name: 'Philippine Peso', symbol: '₱' },
  PKR: { id: '111', code: 'PKR', name: 'Pakistani Rupee', symbol: '₨' },
  PLN: { id: '112', code: 'PLN', name: 'Polish Zloty', symbol: 'zł' },
  PYG: { id: '113', code: 'PYG', name: 'Paraguayan Guarani', symbol: '₲' },
  QAR: { id: '114', code: 'QAR', name: 'Qatari Riyal', symbol: 'ر.ق' },
  RON: { id: '115', code: 'RON', name: 'Romanian Leu', symbol: 'lei' },
  RSD: { id: '116', code: 'RSD', name: 'Serbian Dinar', symbol: 'дин.' },
  RUB: { id: '117', code: 'RUB', name: 'Russian Ruble', symbol: '₽' },
  RWF: { id: '118', code: 'RWF', name: 'Rwanda Franc', symbol: 'FRw' },
  SAR: { id: '119', code: 'SAR', name: 'Saudi Riyal', symbol: 'ر.س' },
  SBD: { id: '120', code: 'SBD', name: 'Solomon Islands Dollar', symbol: '$' },
  SCR: { id: '121', code: 'SCR', name: 'Seychelles Rupee', symbol: '₨' },
  SDG: { id: '122', code: 'SDG', name: 'Sudanese Pound', symbol: 'ج.س.' },
  SGD: { id: '123', code: 'SGD', name: 'Singapore Dollar', symbol: '$' },
  SHP: { id: '124', code: 'SHP', name: 'St. Helena Pound', symbol: '£' },
  SLL: { id: '125', code: 'SLL', name: 'Sierra Leonean Leone', symbol: 'Le' },
  SOS: { id: '126', code: 'SOS', name: 'Somali Shilling', symbol: 'Sh' },
  SRD: { id: '127', code: 'SRD', name: 'Surinam Dollar', symbol: '$' },
  STD: {
    id: '128',
    code: 'STD',
    name: 'Sao Tome and Principe Dobra',
    symbol: 'Db',
  },
  SVC: { id: '129', code: 'SVC', name: 'El Salvador Colon', symbol: '₡' },
  SYP: { id: '130', code: 'SYP', name: 'Syrian Pound', symbol: '£S' },
  SZL: { id: '131', code: 'SZL', name: 'Swaziland Lilangeni', symbol: 'L' },
  THB: { id: '132', code: 'THB', name: 'Thai Baht', symbol: '฿' },
  TJS: { id: '133', code: 'TJS', name: 'Tajikistani Somoni', symbol: 'ЅМ' },
  TMT: { id: '134', code: 'TMT', name: 'Turkmenistani Manat', symbol: 'm' },
  TND: { id: '135', code: 'TND', name: 'Tunisian Dinar', symbol: 'د.ت' },
  TOP: { id: '136', code: 'TOP', name: "Tonga Pa'anga", symbol: 'T$' },
  TRY: { id: '137', code: 'TRY', name: 'Turkish Lira', symbol: '₺' },
  TTD: {
    id: '138',
    code: 'TTD',
    name: 'Trinidad & Tobago Dollar',
    symbol: 'TT$',
  },
  TWD: { id: '139', code: 'TWD', name: 'Taiwanese Dollar', symbol: 'NT$' },
  TZS: { id: '140', code: 'TZS', name: 'Tanzanian Shilling', symbol: 'Sh' },
  UAH: { id: '141', code: 'UAH', name: 'Ukrainian Hryvnia', symbol: '₴' },
  UGX: { id: '142', code: 'UGX', name: 'Ugandan Shilling', symbol: 'USh' },
  UYU: { id: '143', code: 'UYU', name: 'Uruguayan Peso', symbol: '$U' },
  UZS: { id: '144', code: 'UZS', name: 'Uzbekistan Som', symbol: 'UZS' },
  VEF: {
    id: '145',
    code: 'VEF',
    name: 'Venezuealan Bolivar Fuerte',
    symbol: 'Bs F',
  },
  VND: { id: '146', code: 'VND', name: 'Vietnam Dong', symbol: '₫' },
  VUV: { id: '147', code: 'VUV', name: 'Vanuatu Vatu', symbol: 'VT' },
  WST: { id: '148', code: 'WST', name: 'Samoa Tala', symbol: 'WS$' },
  XAF: { id: '149', code: 'XAF', name: 'CFA Franc', symbol: 'FCFA' },
  XCD: { id: '150', code: 'XCD', name: 'East Caribbean Dollar', symbol: '$' },
  XOF: { id: '151', code: 'XOF', name: 'CFA Franc', symbol: 'CFA' },
  XPF: { id: '152', code: 'XPF', name: 'CFP Franc', symbol: 'CFP' },
  YER: { id: '153', code: 'YER', name: 'Yemeni Rial', symbol: '﷼' },
  ZAR: { id: '154', code: 'ZAR', name: 'South African Rand', symbol: 'R' },
  ZMW: { id: '155', code: 'ZMW', name: 'Zambian Kwacha', symbol: 'ZK' },
};

export const getCurrencyByCode = (code: string | null): CurrencyDto => {
  return code ? currencies[code] ?? usdCurrency : usdCurrency;
};
