import { Box, type SxProps, type Theme } from '@mui/material';

type ContextPickerBadgeProps = {
  color: string;
  text: string;
  sx?: SxProps<Theme> | undefined;
};

import React from 'react';

export default function ContextPickerBadge({
  color,
  text,
  sx = {},
}: ContextPickerBadgeProps) {
  return (
    <Box
      sx={{
        position: 'absolute',
        backgroundColor: '#FFF',
        borderRadius: '50%',
        ...sx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 24,
          width: 24,
          color: color,
          fontSize: 10,
          backgroundColor: `${color}33`,
          border: '2px solid #FFF',
          borderRadius: '50%',
        }}
      >
        {text}
      </Box>
    </Box>
  );
}
