import { Box } from '@mui/material';
import type React from 'react';

type ProfileMenuDropdownActionProps = {
  onClick: () => void;
  children: React.ReactNode;
};

export default function ProfileMenuDropdownAction({
  onClick,
  children,
}: ProfileMenuDropdownActionProps) {
  return (
    <Box
      sx={{
        px: 2,
        py: 1,
        display: 'flex',
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: 'secondary.light',
        },
      }}
      onClick={() => onClick()}
    >
      {children}
    </Box>
  );
}
