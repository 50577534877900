import AttachFileIcon from '@mui/icons-material/AttachFile';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import type { AllowedExtensions } from 'components/trips/attachments/types';
import type { ListItem } from 'components/types';
import moment from 'moment-timezone';

export const capitalize = (string: string) =>
  string
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.toLowerCase().slice(1))
    .join(' ');

export const roundToTwoDecimals = (value: number) =>
  Math.round(value * 100) / 100;

export const formatPercentage = roundToTwoDecimals;

export const formatMoney = (
  value: number | undefined,
  zeroValueDisplay: string | JSX.Element = '$0',
  symbol = '$',
): JSX.Element => (
  <>
    {value
      ? `${symbol}${value.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`
      : zeroValueDisplay}
  </>
);

export const formatMoneyStr = (
  value: number | undefined,
  zeroValueDisplay = '$0',
  symbol = '$',
): string =>
  value
    ? `${symbol}${value.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`
    : zeroValueDisplay;

export const formatMoneyOutOfTotal = (
  value: number | undefined,
  total: number | undefined,
  zeroValueDisplay = '$0',
  symbol = '$',
): string =>
  value !== undefined
    ? `${formatMoneyStr(value, zeroValueDisplay, symbol)} / ${formatMoneyStr(
        total,
        zeroValueDisplay,
        symbol,
      )}`
    : zeroValueDisplay;

export const niceShortDate = (value?: string | Date): string =>
  value ? moment(value).format('D MMM YYYY') : '';

export const niceShortDateTime = (
  value?: string | Date,
  timeSeparator: ',' | 'at' = ',',
): string =>
  value
    ? moment(value).format(
        `D MMM YYYY${timeSeparator === ',' ? ', ' : ' \\at'} h:mma`,
      )
    : '';

export const niceShortTime = (value?: string | Date): string =>
  value ? moment(value).format('h:mma') : '';

export const niceDate = (value?: string | Date): string =>
  value ? moment.utc(value).format('ddd MMM D') : '';

export const officialDate = (value?: string | Date): string =>
  value ? moment.utc(value).format('M/D/YYYY') : '';

export function singleLine(
  strings: TemplateStringsArray,
  ...values: (string | number)[]
) {
  let output = '';
  for (let i = 0; i < values.length; i++) {
    output += strings[i] + values[i];
  }
  output += strings[values.length];

  // Split on newlines.
  const lines = output.split(/(?:\r\n|\n|\r)/);

  // Rip out the leading whitespace.
  return lines
    .map((line) => {
      return line.replace(/^\s+/gm, '');
    })
    .join('')
    .trim();
}

export const tagsDisplay = (tags: ListItem[]): string =>
  tags.map((t) => t.name).join(', ');

export const stringsDisplay = (strings: string[]): string => strings.join(', ');

export const convertBytesToHumanFileSize = (size: number): string => {
  const bytesString = ['B', 'KB', 'MB', 'GB', 'TB'];
  const bytesStringIndex: number =
    size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));

  const bytesNumber = Number.parseFloat(
    (size / Math.pow(1024, bytesStringIndex)).toFixed(2),
  );

  return `${bytesNumber}${bytesString[bytesStringIndex]} `;
};

export const removeExtensionFromFilename = (filename: string): string =>
  filename.replace(/\.[^/.]+$/, '');

export const getExtensionFromFilename = (filename: string): string =>
  filename.split('.').pop() ? `.${filename.split('.').pop()}` : '';

export const getFileIconFromFilename = (filename: string) => {
  const extension = getExtensionFromFilename(filename);
  let FileIcon;

  if (['.jpg', '.jpeg', '.png', '.svg'].includes(extension)) {
    FileIcon = ImageIcon;
  } else if (extension === '.pdf') {
    FileIcon = PictureAsPdfIcon;
  } else {
    FileIcon = AttachFileIcon;
  }
  return FileIcon;
};

export const getFileIconFromFileType = (type: File['type']) => {
  let FileIcon;

  if (type.startsWith('image/')) {
    FileIcon = ImageIcon;
  } else if (type === 'application/pdf') {
    FileIcon = PictureAsPdfIcon;
  } else {
    FileIcon = AttachFileIcon;
  }

  return FileIcon;
};

export const getMimeTypes = (extensions: AllowedExtensions[]) => {
  const mimeTypeMap: Record<AllowedExtensions, string> = {
    '.png': 'image/png',
    '.jpg': 'image/jpeg',
    '.jpeg': 'image/jpeg',
    '.pdf': 'application/pdf',
    '.svg': 'image/svg+xml',
    '.csv': 'text/csv',
    '.xls': 'application/vnd.ms-excel',
    '.xlsx':
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    '.doc': 'application/msword',
    '.docx':
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  };

  const allowedMimeTypes: {
    [key: string]: AllowedExtensions[];
  } = {};

  extensions.forEach((ext) => {
    const mimeType = mimeTypeMap[ext];
    if (mimeType) {
      if (!allowedMimeTypes[mimeType]) {
        allowedMimeTypes[mimeType] = [];
      }
      allowedMimeTypes[mimeType].push(ext);
    }
  });

  return allowedMimeTypes;
};
