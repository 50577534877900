import type { BookingResponseDto } from 'dtos';
import useSWR from 'swr';

import { fetcher } from '../utils/client/swr/jsonFetcher';

type UseSupplierBookingProps = {
  supplierId?: string;
  bookingId?: string;
};

export default function useSupplierBooking({
  supplierId,
  bookingId = '',
}: UseSupplierBookingProps) {
  const key = `suppliers/${supplierId}/bookings/${bookingId}`;

  const {
    data,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<BookingResponseDto, Error>(
    key,
    supplierId && bookingId ? fetcher : null,
    {
      revalidateOnFocus: false,
    },
  );

  return {
    booking: data as BookingResponseDto,
    isLoading,
    error,
    refresh: mutate,
  };
}
