import useSWR from 'swr';

import type { AgencyResponseDto } from 'dtos';
import type { PaginatedData, SortModel } from '../types/paginatedData';
import { fetcher } from '../utils/client/swr/jsonFetcher';

type UseAgenciesProps = {
  query?: string;
  page?: number;
  pageSize?: number;
  getAll?: true;
  sortModel: SortModel;
};

export default function useAgencies({
  query = '',
  page = 0,
  pageSize = 20,
  getAll,
  sortModel: { sort, sortDirection },
}: UseAgenciesProps) {
  const {
    data,
    isValidating: isLoading,
    error,
  } = useSWR<PaginatedData<AgencyResponseDto>, Error>(
    `agencies?query=${query}&page=${page}&pageSize=${
      getAll ? 1000000 : pageSize
    }&sort=${sort}&sortDirection=${sortDirection}`,
    fetcher,
    {
      revalidateOnFocus: false,
    },
  );

  const { data: agencies = [], meta } =
    (data as PaginatedData<AgencyResponseDto>) || {};

  return { agencies, meta, isLoading, error };
}
