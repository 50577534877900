import type { UserProfileResponseDto } from 'dtos';
import useSWR from 'swr';

import { fetcher } from '../utils/client/swr/jsonFetcher';

export default function useUserProfile() {
  const key = 'users/me/profile';

  const {
    data,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<UserProfileResponseDto, Error>(key, fetcher, {
    revalidateOnFocus: false,
  });

  return {
    userProfile: data as UserProfileResponseDto,
    isLoading,
    error,
    refresh: mutate,
  };
}
