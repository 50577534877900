import type {
  TripSummaryListItemResponseDto,
  TripSummaryPaginatedResponseDto,
} from 'dtos';
import useSWR from 'swr';
import type { PaginatedData } from 'types/paginatedData';

import { fetcher } from '../utils/client/swr/jsonFetcher';

export default function useTripSummary(
  tripId: string,
  fallbackSummary?: TripSummaryPaginatedResponseDto,
) {
  const params = new URLSearchParams({ page: '0', pageSize: '1000000' });

  const {
    data,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<PaginatedData<TripSummaryListItemResponseDto>, Error>(
    `trips/${tripId}/summary?${params.toString()}`,
    fetcher,
    {
      revalidateOnFocus: false,
      fallbackData:
        fallbackSummary as unknown as PaginatedData<TripSummaryListItemResponseDto>,
    },
  );
  const { data: summary = [], meta } =
    (data as PaginatedData<TripSummaryListItemResponseDto>) || {};

  return { summary, meta, isLoading, error, refresh: mutate };
}
