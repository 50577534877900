import type { IdentifierResponseDto } from 'dtos';
import useSWR from 'swr';

import type {
  PaginatedData,
  PaginatedDataResponse,
  SortModel,
} from '../types/paginatedData';
import { fetcher } from '../utils/client/swr/jsonFetcher';

type UseIdentifierProps = {
  query?: string;
  page: number;
  pageSize: number;
  sortModel: SortModel;
};

export default function useIdentifiers({
  query = '',
  page = 1,
  pageSize = 20,
  sortModel: { sort, sortDirection },
}: UseIdentifierProps): PaginatedDataResponse<IdentifierResponseDto> {
  const key = `identifiers?query=${query}&page=${page}&pageSize=${pageSize}&sort=${sort}&sortDirection=${sortDirection}`;

  const {
    data: response,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<PaginatedData<IdentifierResponseDto>, Error>(key, fetcher, {
    revalidateOnFocus: false,
  });

  const { data, meta } =
    (response as PaginatedData<IdentifierResponseDto>) || {};

  return { data, meta, isLoading, error, refresh: () => void mutate() };
}
