import { Avatar, type SxProps, useTheme } from '@mui/material';

import T from './T';

export type UserAvatarProps = {
  color: string;
  firstName: string;
  lastName: string;
  profilePictureUri?: string | null;
  sx?: SxProps;
};

export default function UserAvatar({
  color,
  firstName,
  lastName,
  profilePictureUri,
  sx = {},
}: UserAvatarProps): JSX.Element {
  const theme = useTheme();

  const sxValues = {
    ...sx,
    ...(color
      ? {
          color: `${color}!important`,
          backgroundColor: `${color}33`,
        }
      : { backgroundColor: theme.palette.background.lighter }),
  };

  const getInitials = (firstName: string, lastName: string) => {
    return `${(firstName?.length && firstName[0]) || ''}${
      (lastName?.length && lastName[0]) || ''
    }`;
  };

  return profilePictureUri ? (
    <Avatar sx={sxValues} src={profilePictureUri} />
  ) : (
    <Avatar sx={sxValues}>
      <T>{getInitials(firstName, lastName)}</T>
    </Avatar>
  );
}
