import type { SupplierContactResponseDto } from 'dtos';
import useSWR from 'swr';

import { fetcher } from '../utils/client/swr/jsonFetcher';

type UseSupplierContactProps = {
  supplierId?: string;
  supplierContactId?: string;
};

export default function useSupplierContact({
  supplierId,
  supplierContactId = '',
}: UseSupplierContactProps) {
  const key = `suppliers/${supplierId}/contacts/${supplierContactId}`;

  const {
    data,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<SupplierContactResponseDto, Error>(
    key,
    supplierId && supplierContactId ? fetcher : null,
    {
      revalidateOnFocus: false,
    },
  );

  return {
    supplierContact: data as SupplierContactResponseDto,
    isLoading,
    error,
    refresh: mutate,
  };
}
