import type { BookingResponseDto, InvoiceStatus } from 'dtos';
import useSWR from 'swr';

import { getUrlSearchParams } from 'utils/client/fetch';
import type { PaginatedData, SortModel } from '../types/paginatedData';
import { fetcher } from '../utils/client/swr/jsonFetcher';

export type UseBookingsProps = {
  query: string;
  page: number;
  pageSize: number;
  sortModel: SortModel;
  statusFilter?: InvoiceStatus;
  whatFor?: 'bookings' | 'commissions';
  daysToSearch?: number;
  hideNonCommissionable?: boolean;
};

export default function useBookings({
  query = '',
  page = 1,
  pageSize = 20,
  sortModel: { sort, sortDirection },
  statusFilter,
  whatFor,
  daysToSearch,
  hideNonCommissionable = false,
}: UseBookingsProps) {
  const params = getUrlSearchParams({
    query,
    page,
    pageSize,
    sort,
    sortDirection,
    statusFilter,
    whatFor,
    daysToSearch,
    hideNonCommissionable,
  });

  const endpoint = `bookings?${params}`;

  const {
    data: response,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<PaginatedData<BookingResponseDto>, Error>(endpoint, fetcher, {
    revalidateOnFocus: false,
  });

  const { data, meta } = (response as PaginatedData<BookingResponseDto>) || {};

  return { data, meta, isLoading, error, refresh: mutate };
}
