import type { LeadResponseDto } from 'dtos';
import useSWR from 'swr';

import { fetcher } from '../utils/client/swr/jsonFetcher';

export default function useLead(leadId: string | null) {
  const key = `leads/${leadId}`;

  const {
    data,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<LeadResponseDto>(key, (leadId && fetcher) || null, {
    revalidateOnFocus: false,
  });

  const lead = data as LeadResponseDto;

  return { lead, isLoading, error, refresh: mutate };
}
