import SwapVertIcon from '@mui/icons-material/SwapVert';
import { Box, Popover, Stack, Typography as T } from '@mui/material';
import type { Context } from 'components/types';
import type { ContextAgencyDto } from 'dtos';
import React, { type MouseEvent } from 'react';
import { getAgencyHierarchy, getInitials } from 'utils/client/contextTree';

import ContextAgencyAvatar from './ContextAgencyAvatar';
import ContextAutoComplete from './ContextAutoComplete';

type ContextPickerProps = {
  agencies: ContextAgencyDto[];
  value: Context | null;
  setValue: (context: Context | null) => Promise<null>;
};

function ContextPicker({ agencies, value, setValue }: ContextPickerProps) {
  const [isOpen, setIsOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & Element) | null
  >(null);

  const handleClick = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  const hierarchy = getAgencyHierarchy(agencies);

  const agencyNode = value
    ? (agencies.find((a) => a.agency.id === value.agencyId) as ContextAgencyDto)
    : null;

  const agencyInitials = agencyNode ? getInitials(agencyNode.agency.name) : '';
  const userNode = agencyNode?.users.find((u) => u.id === value?.userId);

  const userInitials = userNode ? getInitials(userNode?.name || '') : '';

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          px: 2,
          minHeight: '64px',
          maxWidth: 300,
          overflow: 'hidden',
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'secondary.light',
          },
        }}
        onClick={handleClick}
      >
        {agencyNode && (
          <ContextAgencyAvatar
            agencyInitials={agencyInitials}
            userInitials={userInitials}
            color={agencyNode?.color || ('#EEE' as string)}
          />
        )}
        <Stack sx={{ ml: value ? 2 : 0, maxWidth: 'calc(100% - 48px)' }}>
          <Box>
            <T variant="body2" color="text.secondary" whiteSpace="nowrap">
              Viewing as
            </T>
          </Box>
          <Box>
            {!value && <T>Admin</T>}
            {agencyNode && !userNode && (
              <T
                maxWidth="100%"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {agencyNode.agency.name}
              </T>
            )}
            {agencyNode && userNode && (
              <T whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                {userNode.name} ({agencyNode.agency.name})
              </T>
            )}
          </Box>
        </Stack>
        <Box sx={{ ml: 2 }}>
          <SwapVertIcon
            sx={{
              color: 'action.active',
            }}
          />
        </Box>
      </Box>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          sx={{
            width: 400,
            maxHeight: 'calc(100vh - 80px)',
            backgroundColor: '#fff',
          }}
        >
          <ContextAutoComplete
            value={value}
            setValue={(context: Context | null) => {
              setIsOpen(false);
              void setValue(context);
            }}
            hierarchy={hierarchy}
          />
        </Box>
      </Popover>
    </>
  );
}

export default ContextPicker;
