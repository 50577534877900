import { Box } from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import type { HierarchicalAgency } from 'components/types';
import type { ProfileResponseDto } from 'dtos';
import { getInitials } from 'utils/client/contextTree';

function ContextAutoCompleteUserNode({
  node,
  user,
  query,
  onSelect,
}: {
  node: HierarchicalAgency;
  user: ProfileResponseDto;
  query: string;
  onSelect: ({
    agencyId,
    userId,
  }: {
    agencyId: string;
    userId?: string;
  }) => void;
}) {
  const initials = getInitials(user.name);

  const matches = match(user.name, query, {
    findAllOccurrences: true,
    insideWords: true,
  });
  const parts = parse(user.name, matches);

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      sx={{
        ml: `${20 * node.depth}px`,
        p: 1,
        fontSize: 14,
        '&:hover': {
          backgroundColor: 'secondary.light',
          cursor: 'pointer',
        },
      }}
      onClick={() => onSelect({ agencyId: node.id, userId: user.id })}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 24,
          width: 24,
          color: node.color,
          fontSize: 10,
          backgroundColor: `${node.color}33`,
          borderRadius: '50%',
          mr: '12px',
        }}
      >
        {initials}
      </Box>
      {parts.map((part, index) => (
        <span
          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
          key={index}
          style={{
            fontWeight: part.highlight ? 700 : 400,
          }}
        >
          {part.text.replace(/ /g, '\u00A0')}
        </span>
      ))}
    </Box>
  );
}
export default ContextAutoCompleteUserNode;
