import type { ContextTokenRequestDto, ContextTokenResponseDto } from 'dtos';
import ky from 'utils/client/ky';

export const getContextToken = async (
  context: ContextTokenRequestDto,
): Promise<ContextTokenResponseDto> =>
  ky
    .post('/api/context/token', {
      json: context,
    })
    .json<ContextTokenResponseDto>();
