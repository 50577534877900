import {
  queryTypes as qt,
  useQueryState,
  useQueryStates,
} from 'next-usequerystate';
import type { SortModel } from 'types/paginatedData';

const usePagination = <T = { [key: string]: unknown }>(
  defaultSortColumn: keyof T,
  defaultSortDirection: 'asc' | 'desc' = 'asc',
  prefix = '',
) => {
  const [sortModel, setSortModel] = useQueryStates(
    {
      [`${prefix}sort`]: qt.string.withDefault(defaultSortColumn as string),
      [`${prefix}sortDirection`]: qt
        .stringEnum(['asc', 'desc'])
        .withDefault(defaultSortDirection),
    },
    {
      history: 'replace',
    },
  );

  const [page, setPage] = useQueryState(
    `${prefix}page`,
    qt.integer.withDefault(0),
  );
  const [pageSize, setPageSize] = useQueryState(
    `${prefix}pageSize`,
    qt.integer.withDefault(100),
  );

  return {
    sortModel: {
      sort: sortModel[`${prefix}sort`],
      sortDirection: sortModel[`${prefix}sortDirection`] as 'asc' | 'desc',
    } as SortModel,
    setSortModel: (sortModel: SortModel) =>
      setSortModel({
        [`${prefix}sort`]: sortModel.sort,
        [`${prefix}sortDirection`]: sortModel.sortDirection,
      }),
    page,
    setPage,
    pageSize,
    setPageSize,
  };
};

export default usePagination;
