import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LaunchIcon from '@mui/icons-material/Launch';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Divider, Popover, Stack } from '@mui/material';
import T from 'components/common/T';
import type { UserProfileResponseDto } from 'dtos';
import React, { useState } from 'react';

import { useUserInfo } from 'hooks';
import ProfileMenuDropdownAction from './ProfileMenuDropdownAction';
import UserAvatar from './UserAvatar';

interface ProfileMenuDropdownProps extends UserProfileResponseDto {
  signOut: () => void;
  handleGoToSettings: () => void;
}

export default function ProfileMenuDropdown({
  signOut,
  handleGoToSettings,
  ...profile
}: ProfileMenuDropdownProps) {
  const [showUserDropdown, setShowUserDropdown] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & Element) | null
  >(null);

  const { userInfo } = useUserInfo();

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget as EventTarget & Element);
    setShowUserDropdown(true);
  };

  const handleClose = () => {
    setShowUserDropdown(false);
    setAnchorEl(null);
  };

  const goToSettings = () => {
    handleClose();
    handleGoToSettings();
  };

  const logOut = () => {
    handleClose();
    signOut();
  };

  const goToHelp = () => {
    userInfo.isOrgUser
      ? window.open(
          'https://tripsuiteadmin.reveal.pub/?siteKey=rev-site-.CS34ZMY-GTQU6FY-R2VO37A-Z3MUPHA',
          '_blank',
        )
      : window.open(
          'https://tripsuite.reveal.pub/?siteKey=rev-site-.TZDQVUY-TXAUKGA-XFVPHOQ-C5EAHRY',
          '_blank',
        );
  };

  const goToFeedback = () => {
    window.open('https://tripsuite.canny.io/feedback', '_blank');
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems={'center'}
        minHeight="64px"
        pl={2}
        pr={3}
        sx={{
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'secondary.light',
          },
        }}
        onClick={handleClick}
      >
        <UserAvatar
          color={profile.agencyColor}
          firstName={profile.firstName}
          lastName={profile.lastName}
          profilePictureUri={profile.profileImageUrl}
        />
        <Box px={2}>
          <T variant="body2">
            {profile.firstName} {profile.lastName}
          </T>
        </Box>
        <ArrowDropDownIcon
          sx={{
            color: 'action.active',
          }}
        />
      </Stack>

      <Popover
        open={showUserDropdown}
        anchorEl={anchorEl}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#fff',
            minWidth: '200px',
          }}
        >
          <Box px={2} pb={1} pt={2}>
            <T variant="body1" color="text.primary">
              {profile.firstName} {profile.lastName}
            </T>
            <T variant="body2" color="text.secondary">
              {profile.email}
            </T>
          </Box>

          <Divider />

          <ProfileMenuDropdownAction onClick={goToSettings}>
            <SettingsIcon
              sx={{
                color: 'action.active',
              }}
            />
            <T pl={2}>Settings</T>
          </ProfileMenuDropdownAction>

          <Divider />

          {process.env.NEXT_PUBLIC_HELP_BUTTON_ENABLED === '1' ? (
            <ProfileMenuDropdownAction onClick={goToHelp}>
              <HelpOutlineIcon
                sx={{
                  color: 'action.active',
                }}
              />
              <T pl={2}>Help</T>
              <Box sx={{ flexGrow: 1 }} />
              <LaunchIcon sx={{ color: 'action.active' }} fontSize="small" />
            </ProfileMenuDropdownAction>
          ) : undefined}

          <ProfileMenuDropdownAction onClick={goToFeedback}>
            <HelpOutlineIcon
              sx={{
                color: 'action.active',
              }}
            />
            <T pl={2}>Feedback</T>
            <Box sx={{ flexGrow: 1 }} />
            <LaunchIcon sx={{ color: 'action.active' }} fontSize="small" />
          </ProfileMenuDropdownAction>

          <Divider />

          <ProfileMenuDropdownAction onClick={logOut}>
            <T variant="button" textTransform={'uppercase'}>
              log out
            </T>
          </ProfileMenuDropdownAction>
        </Box>
      </Popover>
    </>
  );
}
