import { SignIn as ClerkSignIn } from '@clerk/nextjs';
import { Box } from '@mui/material';
import { useRouter } from 'next/router';
import React from 'react';

export default function SignIn() {
  const router = useRouter();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <ClerkSignIn redirectUrl={router.asPath} />
    </Box>
  );
}
