import { Context, type HierarchicalAgency } from 'components/types';
import { getCookie } from 'cookies-next';
import type { ContextAgencyDto, ContextToken, ContextTokenDto } from 'dtos';
import { getUnverifiedJwtContent } from 'utils/client/jwt';

export function getInitials(name: string): string {
  const nameParts = name.split(' ').filter((x, idx) => idx < 2);
  let initials = '';
  for (const part of nameParts) {
    initials += part.charAt(0).toUpperCase();
  }
  return initials;
}

export function getAgencyHierarchy(
  agencies: ContextAgencyDto[],
): HierarchicalAgency[] {
  // First, create a map of agency ids to agency objects
  const agencyMap = new Map();
  for (const agency of agencies) {
    agencyMap.set(agency.agency.id, agency);
  }

  // Next, create the flat array of objects with children and depth
  const flatAgencies = [];
  for (const agency of agencies.filter(
    (a) => !a.parentAgencyId || !agencyMap.has(a.parentAgencyId),
  )) {
    // Recursively find the children of this agency
    const children = findChildren(agency, agencies);

    // Create a flat representation of the agency
    const flatAgency = {
      id: agency.agency.id,
      name: agency.agency.name,
      color: agency.color,
      users: agency.users,
      singleUserAgency: agency.singleUserAgency,
      children: children,
      depth: 0,
    };
    flatAgencies.push(flatAgency);
  }

  return flatAgencies;
}

function findChildren(
  agency: ContextAgencyDto,
  agencies: ContextAgencyDto[],
  depth = 0,
) {
  const children: HierarchicalAgency[] = [];
  for (const a of agencies) {
    if (a.parentAgencyId === agency.agency.id) {
      // This agency is a child of the current agency, so add it to the list of children
      const child = {
        id: a.agency.id,
        name: a.agency.name,
        color: a.color,
        users: a.users,
        singleUserAgency: a.singleUserAgency,
        children: findChildren(a, agencies, depth + 1),
        depth: depth + 1,
      };
      children.push(child);
    }
  }
  return children;
}

export function depthFirstSearch(
  nodes: HierarchicalAgency[],
  query: string,
): HierarchicalAgency[] | null {
  const search = (result: HierarchicalAgency[], node: HierarchicalAgency) => {
    const q = query?.toLowerCase();

    const getMatchingUsers = () =>
      node.users.filter(
        (u) =>
          node.name.toLowerCase().includes(q) ||
          u.name.toLowerCase().includes(q),
      );

    if (
      node.name.toLowerCase().includes(q) ||
      node.users.some((u) => u.name.toLowerCase().includes(q))
    ) {
      result.push({
        ...node,
        users: getMatchingUsers(),
        children: node.children.reduce(search, []),
      });
      return result;
    }

    const matchingChildren = node.children.reduce(search, []);
    if (matchingChildren?.length)
      result.push({
        ...node,
        users: getMatchingUsers(),
        children: matchingChildren,
      });

    return result;
  };

  return nodes.reduce(search, []);
}

export function getContextFromCookie(): ContextToken | null {
  const contextCookie = getCookie('context');
  return contextCookie
    ? getUnverifiedJwtContent<ContextTokenDto>(
        JSON.parse(contextCookie as string).token,
      )?.ctx ?? null
    : null;
}
