import z from 'zod';
// cspell:disable
import { PaymentStatusEnum } from './paymentProcessor-shared.dtos';

// Define a string schema that trims and lowercases the input
export const trimmedLowercasedStringEmail = z
  .string()
  .email()
  .transform((str) => str.trim().toLowerCase());

export const MoneySchema = z.object({
  currency: z.string().max(3).default('USD').optional(),
  value: z.number(),
});
export type MoneySchema = z.infer<typeof MoneySchema>;

export enum MoovPaymentMethod {
  MoovWallet = 'moov-wallet',
  AchDebitFund = 'ach-debit-fund',
  AchDebitCollect = 'ach-debit-collect',
  AchCreditStandard = 'ach-credit-standard',
  AchCreditSameDay = 'ach-credit-same-day',
  RtpCredit = 'rtp-credit',
  CardPayment = 'card-payment',
  ApplePay = 'apple-pay',
  PushToCard = 'push-to-card',
}

export const PaymentMethodTypeEnum = z.nativeEnum(MoovPaymentMethod);
export type PaymentMethodTypeEnum = z.infer<typeof PaymentMethodTypeEnum>;

export const PaymentMethodAccountResponseDto = z.object({
  accountId: z.string().uuid(),
  token: z.string(),
  type: z.literal('error').optional(),
  code: z.number().optional(),
  message: z.string().optional(),
  error: z.any().optional(),
});
export type PaymentMethodAccountResponseDto = z.infer<
  typeof PaymentMethodAccountResponseDto
>;
const AddressDto = z.object({
  addressLine1: z.string(),
  addressLine2: z.string().default(''),
  city: z.string(),
  stateOrProvince: z.string(),
  postalCode: z.string(),
  country: z.string(),
});
// FE to T.S. BE

export const PaymentMethodAccountCreateDto = z.object({
  name: z.string(),
  email: trimmedLowercasedStringEmail,
  // TODO: not sure yet whether we've decided to not capture full address.
  // address: AddressDto,
  type: z.enum(['individual', 'business']).default('individual'),
  // TODO: we get the paymentType in the response from the create card in Moov.js
  paymentType: PaymentMethodTypeEnum.default(MoovPaymentMethod.CardPayment), // comment
  tripInvoiceInstallmentId: z.string().uuid(),
});

export type PaymentMethodAccountCreateDto = z.infer<
  typeof PaymentMethodAccountCreateDto
>;

export const CardBrandEnum = z
  .enum(['American Express', 'Discover', 'Mastercard', 'Visa'])
  .optional();
export const CardTypeEnum = z.enum(['debit', 'credit', 'prepaid', 'unknown']);
export const DomesticPushToCardEnum = z
  .enum(['not-supported', 'standard', 'fast-funds', 'unknown'])
  .optional();
export const CardUpdateTypeEnum = z.enum([
  'unspecified',
  'account-closed',
  'contact-cardholder',
  'expiration-update',
  'no-change',
  'no-match',
  'number-update',
]);
export const VerificationStatusEnum = z.enum([
  'noMatch',
  'match',
  'notChecked',
  'unavailable',
]);
const BankAccountTypeEnum = z.enum(['checking', 'savings', 'unknown']);
const AchReturnCodeEnum = z.enum([
  'R02',
  'R03',
  'R04',
  'R05',
  'R07',
  'R08',
  'R10',
  'R11',
  'R12',
  'R13',
  'R14',
  'R15',
  'R16',
  'R17',
  'R20',
  'R23',
  'R29',
  'R34',
  'R38',
  'R39',
]);
const HolderTypeEnum = z.enum(['individual', 'business']);
const AccountStatusEnum = z.enum([
  'new',
  'verified',
  'verificationFailed',
  'pending',
  'errored',
]);
const StatusReasonEnum = z.enum([
  'bank-account-created',
  'verification-initiated',
  'micro-deposit-attempts-exceeded',
  'micro-deposit-expired',
  'max-verification-failures',
  'verification-successful',
  'ach-debit-return',
  'ach-credit-return',
  'micro-deposit-return',
  'admin-action',
  'other',
]);
export const CardAccountUpdaterSchema = z
  .object({
    updateType: CardUpdateTypeEnum.optional(),
    updatedOn: z.string().optional(), // Assuming date-time string
  })
  .optional();

export const CardVerificationSchema = z
  .object({
    addressLine1: VerificationStatusEnum,
    cvv: VerificationStatusEnum,
    postalCode: VerificationStatusEnum,
  })
  .optional();

export const ExpirationSchema = z.object({
  month: z.string().length(2),
  year: z.string().length(2),
});

export const CardSchema = z.object({
  billingAddress: AddressDto.optional(),
  bin: z.string().optional(),
  brand: CardBrandEnum.optional(),
  cardAccountUpdater: CardAccountUpdaterSchema.optional(),
  cardID: z.string().uuid().optional(),
  cardOnFile: z.boolean().optional(),
  cardType: CardTypeEnum.optional(),
  cardVerification: CardVerificationSchema.optional(),
  domesticPushToCard: DomesticPushToCardEnum.optional(),
  expiration: ExpirationSchema.optional(),
  fingerprint: z.string().max(100).optional(),
  holderName: z.string().optional(),
  issuer: z.string().optional(),
  issuerCountry: z.string().optional(),
  lastFourCardNumber: z.string().optional(),
  merchantAccountID: z.string().uuid().optional(),
});
export type CardSchema = z.infer<typeof CardSchema>;

export const CardPaymentMethod = z.object({
  paymentMethodID: z.string().uuid(),
  paymentMethodType: PaymentMethodTypeEnum,
  card: CardSchema,
});
export type CardPaymentMethod = z.infer<typeof CardPaymentMethod>;

export const WalletSchema = z.object({
  walletID: z.string(),
});
export type WalletSchema = z.infer<typeof WalletSchema>;

export const WalletPaymentMethod = z.object({
  paymentMethodID: z.string(),
  paymentMethodType: PaymentMethodTypeEnum,
  wallet: WalletSchema,
});
export type WalletPaymentMethod = z.infer<typeof WalletPaymentMethod>;
const ExceptionDetailsSchema = z.object({
  achReturnCode: AchReturnCodeEnum,
  description: z.string(),
});

export const BankAccountSchema = z.object({
  bankAccountID: z.string().uuid().optional(),
  bankAccountType: BankAccountTypeEnum.optional(),
  bankName: z.string().optional(),
  exceptionDetails: ExceptionDetailsSchema.optional(),
  fingerprint: z.string().max(100).optional(),
  holderName: z.string().optional(),
  holderType: HolderTypeEnum.optional(),
  lastFourAccountNumber: z.string().optional(),
  routingNumber: z.string().optional(),
  status: AccountStatusEnum.optional(),
  statusReason: StatusReasonEnum.optional(),
  updatedOn: z.string().optional(), // Assuming date-time string
});
export type BankAccountSchema = z.infer<typeof BankAccountSchema>;

export const BankAccountPaymentMethod = z.object({
  paymentMethodID: z.string(),
  paymentMethodType: PaymentMethodTypeEnum,
  bankAccount: BankAccountSchema,
});
export type BankAccountPaymentMethod = z.infer<typeof BankAccountPaymentMethod>;

export const ApplePaySchema = z.object({
  brand: CardBrandEnum,
  cardDisplayName: z.string(),
  cardType: CardTypeEnum,
  dynamicLastFour: z.string(),
  expiration: ExpirationSchema,
  fingerprint: z.string().max(100),
});
export const ApplePayPaymentMethod = z.object({
  paymentMethodID: z.string(),
  paymentMethodType: PaymentMethodTypeEnum,
  applePay: ApplePaySchema,
});
export type ApplePayPaymentMethod = z.infer<typeof ApplePayPaymentMethod>;
const FailureReasonEnum = z.enum([
  'source-payment-error',
  'destination-payment-error',
  'wallet-insufficient-funds',
  'rejected-high-risk',
  'processing-error',
]);
const FacilitatorFeeSchema = z.object({
  total: z.number().int(),
  markup: z.number().int().optional(),
  markupDecimal: z.string().optional(),
  totalDecimal: z.string().optional(),
});
const MoovFeeDetailsSchema = z.object({
  moovProcessing: z.string(),
  cardScheme: z.string().optional(),
  discount: z.string().optional(),
  interchange: z.string().optional(),
});
const BankAccountStatusEnum = z.enum([
  'new',
  'verified',
  'verificationFailed',
  'pending',
  'errored',
]);
enum AchLifecycleStatusEnum {
  INITIATED = 'initiated',
  ORIGINATED = 'originated',
  CORRECTED = 'corrected',
  RETURNED = 'returned',
  COMPLETED = 'completed',
}
export const AchLifecycleStatus = z.nativeEnum(AchLifecycleStatusEnum);
export type AchLifecycleStatus = z.infer<typeof AchLifecycleStatus>;
// this is the overall status on the Moov transfer response, not the status found on the source object

const dispute = z.object({
  amount: MoneySchema,
  createdOn: z.string().optional(),
  disputeID: z.string().uuid().optional(),
});
const DisputesSchema = z.array(dispute);

export enum CardPaymentStatus {
  INITIATED = 'initiated',
  CONFIRMED = 'confirmed',
  CANCELED = 'canceled',
  SETTLED = 'settled',
  FAILED = 'failed',
  COMPLETED = 'completed',
}

export const CardPaymentStatusEnum = z.nativeEnum(CardPaymentStatus);
export type CardPaymentStatusEnum = z.infer<typeof CardPaymentStatusEnum>;

// Enum for failure codes
const FailureCodeEnum = z.enum([
  'call-issuer',
  'do-not-honor',
  'processing-error',
  'invalid-transaction',
  'invalid-amount',
  'no-such-issuer',
  'reenter-transaction',
  'cvv-mismatch',
  'lost-or-stolen',
  'insufficient-funds',
  'invalid-card-number',
  'invalid-merchant',
  'expired-card',
  'incorrect-pin',
  'transaction-not-allowed',
  'suspected-fraud',
  'amount-limit-exceeded',
  'velocity-limit-exceeded',
  'revocation-of-authorization',
  'card-not-activated',
  'issuer-not-available',
  'could-not-route',
  'cardholder-account-closed',
  'unknown-issue',
  'duplicate-transaction',
]);
// Enum for transaction source
const TransactionSourceEnum = z.enum([
  'first-recurring',
  'recurring',
  'unscheduled',
]);
const CardDetailsSchema = z.object({
  status: CardPaymentStatusEnum,
  canceledOn: z.string().optional(),
  completedOn: z.string().optional(),
  confirmedOn: z.string().optional(),
  dynamicDescriptor: z.string().optional(),
  failedOn: z.string().optional(),
  failureCode: FailureCodeEnum.optional(),
  feeProgram: z.string().optional(), // No specified length, adjust as needed
  initiatedOn: z.string().optional(),
  settledOn: z.string().optional(),
  transactionSource: TransactionSourceEnum.optional(),
});
// Enum for Debit Hold Period
const DebitHoldPeriodEnum = z.enum(['no-hold', '2-days']);
// Correction object schema
const CorrectionSchema = z.object({
  code: z.string().optional(),
  description: z.string().optional(),
  reason: z.string().optional(),
});
// Return object schema
const ReturnSchema = z.object({
  code: z.string().optional(),
  description: z.string().optional(),
  reason: z.string().optional(),
});
const ACHDetailsSchema = z.object({
  companyEntryDescription: z.string().optional(),
  completedOn: z.string().optional(),
  correctedOn: z.string().optional(),
  correction: CorrectionSchema.optional(),
  initiatedOn: z.string().optional(),
  originatedOn: z.string().optional(),
  originatingCompanyName: z.string().optional(),
  return: ReturnSchema.optional(),
  returnedOn: z.string().optional(),
  status: AchLifecycleStatus,
  traceNumber: z.string().max(15),
  debitHoldPeriod: DebitHoldPeriodEnum.optional(),
});
const DestinationAchDetailsSchema = z.object({});
// part of the response we get from Moov for CreateTransfer
const SourceSchema = z.object({
  account: z
    .object({
      accountID: z.string().uuid().optional(),
      displayName: z.string().max(64).optional(),
      email: z.string().email().max(255).optional(),
    })
    .optional(),
  achDetails: ACHDetailsSchema.optional(),
  applePay: ApplePaySchema.optional(),
  bankAccount: BankAccountSchema.optional(),
  card: CardSchema.optional(),
  cardDetails: CardDetailsSchema.optional(),
  paymentMethodID: z.string().uuid().optional(),
  paymentMethodType: PaymentMethodTypeEnum.optional(),
  wallet: WalletSchema.optional(),
  // TODO: check if this is correct. Docs show it here but it's also at the TransferSchema root
  transferID: z.string().uuid().optional(),
});
const RtpLifecycleStatusEnum = z.enum([
  'initiated',
  'completed',
  'failed',
  'accepted-without-posting',
]);
const RtpFailureCodeEnum = z.enum([
  'processing-error',
  'invalid-account',
  'account-closed',
  'account-blocked',
  'invalid-field',
  'transaction-not-supported',
  'limit-exceeded',
  'invalid-amount',
  'customer-deceased',
  'other',
]);
const RtpDetailsSchema = z.object({
  status: RtpLifecycleStatusEnum,
  acceptedWithoutPostingOn: z.string().optional(),
  completedOn: z.string().optional(),
  failedOn: z.string().optional(),
  failureCode: RtpFailureCodeEnum.optional(),
  initiatedOn: z.string().optional(),
  networkResponseCode: z.string().optional(), // Assuming no character limit is specified
});
const DestinationSchema = SourceSchema.extend({
  rtpDetails: RtpDetailsSchema.optional(),
});
const RefundCardDetailsStatusEnum = z.enum([
  'initiated',
  'confirmed',
  'settled',
  'failed',
  'completed',
]);
// Schema for CardDetails in refunds
const RefundCardDetailsSchema = z.object({
  completedOn: z.string().optional(),
  confirmedOn: z.string().optional(),
  failedOn: z.string().optional(),
  failureCode: FailureCodeEnum.optional(),
  initiatedOn: z.string().optional(),
  settledOn: z.string().optional(),
  status: RefundCardDetailsStatusEnum.optional(),
  createdOn: z.string().optional(),
  // Note: failureCode deprecated field can be omitted or marked as optional
});

export enum MoovRefundStatusEnum {
  CREATED = 'created',
  PENDING = 'pending',
  COMPLETED = 'completed',
  FAILED = 'failed',
}
export const MoovRefundStatus = z.nativeEnum(MoovRefundStatusEnum);
export type MoovRefundStatus = z.infer<typeof MoovRefundStatus>;
// Schema for a single Refund object

export const RefundSchema = z.object({
  amount: MoneySchema.optional(),
  cardDetails: RefundCardDetailsSchema.optional(),
  createdOn: z.string().optional(),
  failureCode: FailureCodeEnum.optional(),
  refundID: z.string().uuid().optional(),
  status: MoovRefundStatus.optional(),
  updatedOn: z.string().optional(),
  // these extend the schema provided by Moov
  transferID: z.string().uuid().optional(),
  accountID: z.string().uuid().optional(),
});
export type RefundSchema = z.infer<typeof RefundSchema>;

export const LinkCardDto = z.object({
  accountId: z.string().uuid(),
  tripInvoiceInstallmentId: z.string().uuid(),
  cardNumber: z.string().length(16), // Assuming standard 16 digit card number; adjust as needed
  expiration: ExpirationSchema,
  cardCvv: z.string().length(3).or(z.string().length(4)), // For 3 or 4 digit CVV
  holderName: z.string(),
  billingAddress: AddressDto,
});
// Example of how to extract the TypeScript type from the schema

export type LinkCardDto = z.infer<typeof LinkCardDto>;

export const LinkCardResponseDto = z.object({
  cardId: z.string().uuid(),
  brand: CardBrandEnum.optional(),
  cardType: CardTypeEnum.optional(),
  lastFourCardNumber: z.string().length(4).optional(),
  expiration: ExpirationSchema.optional(),
  holderName: z.string().optional(),
  billingAddress: AddressDto.optional(),
  cardVerification: CardVerificationSchema.optional(),
});
export type LinkCardResponseDto = z.infer<typeof LinkCardResponseDto>;

// TripSuite Dto

// TODO: this may be obsolete now
export const ProcessPaymentCreateDto = z.object({
  accountId: z.string().uuid(), // the Moov account ID
  tripInvoiceInstallmentId: z.string().uuid(),
  // this will be the cardID, bankAccountID, or walletID from Moov, NOT the paymentMethodID
  id: z.string().uuid(),
  type: PaymentMethodTypeEnum.default(MoovPaymentMethod.CardPayment),
  amount: MoneySchema,
});
export type ProcessPaymentCreateDto = z.infer<typeof ProcessPaymentCreateDto>;

export const TripInvoicePaymentCreateRequestDto = z.object({
  email: z.string().email(),
  name: z.string(),
});

export type TripInvoicePaymentCreateRequestDto = z.infer<
  typeof TripInvoicePaymentCreateRequestDto
>;

export enum MoovWebhookEvent {
  ACCOUNT_CREATED = 'account.created', // A new account was created in Moov
  ACCOUNT_UPDATED = 'account.updated', // One of the fields for an existing Moov account was updated
  ACCOUNT_DELETED = 'account.deleted', // An account was deleted
  REPRESENTATIVE_CREATED = 'representative.created', // A representative was added to an account
  REPRESENTATIVE_UPDATED = 'representative.updated', // A representative was updated
  REPRESENTATIVE_DELETED = 'representative.deleted', // A representative was deleted
  CAPABILITY_REQUESTED = 'capability.requested', // A capability was requested for a Moov account
  CAPABILITY_UPDATED = 'capability.updated', // A capability was updated for a Moov account
  BANK_ACCOUNT_CREATED = 'bankAccount.created', // A bank account was created for a Moov account
  BANK_ACCOUNT_UPDATED = 'bankAccount.updated', // A bank account was updated for a Moov account
  BANK_ACCOUNT_DELETED = 'bankAccount.deleted', // A bank account was deleted for a Moov account
  TRANSFER_CREATED = 'transfer.created', // A transfer was created to send money from one account to another
  TRANSFER_UPDATED = 'transfer.updated', // The status of a transfer is pending, completed, failed, or reversed. Granular rail-specific updates on the source and destination also trigger this event.
  DISPUTE_CREATED = 'dispute.created', // A dispute has been created for a particular transfer.
  PAYMENT_METHOD_ENABLED = 'paymentMethod.enabled', // A payment method for account has been enabled
  PAYMENT_METHOD_DISABLED = 'paymentMethod.disabled', // A payment method for account has been disabled
  BALANCE_UPDATED = 'balance.updated', // The balance of a Moov wallet has been updated
  REFUND_CREATED = 'refund.created', // A card payment refund has been created
  REFUND_UPDATED = 'refund.updated', // A card payment refund’s status has changed to pending, completed, or failed
  WALLET_TRANSACTION_UPDATED = 'walletTransaction.updated',
}
// from https://docs.moov.io/guides/webhooks/webhook-events/#transfers
// these almost map to the CardPaymentStatus above but seem to be missing the 'settled' status, but with the additional 'source.' prefix

export enum MoovWebhookEventCardStatus {
  SOURCE_INITIATED = 'source.initiated', // The card transfer was successfully started
  SOURCE_CONFIRMED = 'source.confirmed', // 	The payment request was approved by the cardholder’s bank and the funds are eligible for settlement
  SOURCE_FAILED = 'source.failed', // The payment has failed due to a decline or network error
  SOURCE_COMPLETED = 'source.completed', // Funds have been credited to the destination and are available for use
  SOURCE_CANCELED = 'source.canceled', // The transfer has been canceled and authorization has been reversed

  // NOTE: this is not in their documentation but assuming the documentation is wrong so adding to be safe
  SOURCE_SETTLED = 'source.settled',
}
// from https://docs.moov.io/guides/webhooks/webhook-events/#transfers
// these SOURCE_ prefixed ones map to the AchLifecycleStatusEnum above but with the additional 'source.' prefix

export enum MoovWebhookEventACHSourceStatus {
  SOURCE_INITIATED = 'source.initiated', // The ACH transfer from the source into Moov’s system has been created
  SOURCE_ORIGINATED = 'source.originated', // Payment instructions about the source transfer have been sent to Moov’s originating depository financial institution (ODFI) partner
  SOURCE_CORRECTED = 'source.corrected', // The source transfer completed but a notification of change was received
  SOURCE_COMPLETED = 'source.completed', // Funds are available in Moov and ready to flow out to the destination
  SOURCE_RETURNED = 'source.returned',
}
// from https://docs.moov.io/guides/webhooks/webhook-events/#transfers

export enum MoovWebhookEventACHDestinationStatus {
  DESTINATION_INITIATED = 'destination.initiated', // The ACH transfer from Moov to the destination bank account has been created
  DESTINATION_ORIGINATED = 'destination.originated', // Payment instructions about the destination transfer have been sent to Moov’s receiving depository financial institution (ODFI) partner
  DESTINATION_CORRECTED = 'destination.corrected', // Transfer to the destination completed but a notification of change was received
  DESTINATION_RETURNED = 'destination.returned',
}
// We are splitting this into two enums to make it easier to use in the code so we can determine the status of the source and destination separately
// from https://docs.moov.io/guides/webhooks/webhook-events/#transfers

export const MoovWebhookEventACHStatus = {
  MoovWebhookEventACHSourceStatus,
  MoovWebhookEventACHDestinationStatus,
};
// Main Transfer Schema

export const TransferSchema = z.object({
  transferID: z.string().uuid(),
  facilitatorFee: FacilitatorFeeSchema.optional(),
  moovFeeDetails: MoovFeeDetailsSchema.optional(),
  amount: MoneySchema.optional(),
  completedOn: z.string().optional(),
  createdOn: z.string().optional(),
  description: z.string().max(128).optional(),
  disputedAmount: MoneySchema.optional(),
  disputes: DisputesSchema.optional(),
  failureReason: FailureReasonEnum.optional(),
  groupID: z.string().optional(),
  metadata: z.record(z.string()).optional(),
  moovFee: z.number().int().optional(),
  moovFeeDecimal: z.string().optional(),
  refundedAmount: MoneySchema.optional(),
  refunds: z.array(RefundSchema).optional(),
  status: z.union([
    PaymentStatusEnum,
    z.nativeEnum(MoovWebhookEventCardStatus),
    z.nativeEnum(MoovWebhookEventACHSourceStatus),
    z.nativeEnum(MoovWebhookEventACHDestinationStatus),
  ]),
  destination: DestinationSchema.optional(),
  source: SourceSchema.optional(),
});
export type TransferSchema = z.infer<typeof TransferSchema>;

export const MoovWebhookTransferCreatedData = z.object({
  accountID: z.string().uuid(),
  transferID: z.string().uuid(),
  status: PaymentStatusEnum,
});
export type MoovWebhookTransferCreatedData = z.infer<
  typeof MoovWebhookTransferCreatedData
>;

export const MoovWebhookTransferUpdatedData = TransferSchema.extend({
  accountID: z.string().uuid().optional(),
});
export type MoovWebhookTransferUpdatedData = z.infer<
  typeof MoovWebhookTransferUpdatedData
>;
// Moov uses a different status for the webhook events than the status on the transfer object of the original synchroneous response
// they are the same but prefixed with 'source.' so here we convert that to use the same status as we use for the original transfer object

export function convertMoovEventToCardPaymentStatus(
  status: string,
): CardPaymentStatus | null {
  switch (status) {
    case MoovWebhookEventCardStatus.SOURCE_INITIATED:
      return CardPaymentStatus.INITIATED;
    case MoovWebhookEventCardStatus.SOURCE_CONFIRMED:
      return CardPaymentStatus.CONFIRMED;
    case MoovWebhookEventCardStatus.SOURCE_FAILED:
      return CardPaymentStatus.FAILED;
    case MoovWebhookEventCardStatus.SOURCE_COMPLETED:
      return CardPaymentStatus.COMPLETED;
    case MoovWebhookEventCardStatus.SOURCE_CANCELED:
      return CardPaymentStatus.CANCELED;
    case MoovWebhookEventCardStatus.SOURCE_SETTLED:
      return CardPaymentStatus.SETTLED;
    default:
      return null;
  }
}
// Moov uses a different status for the webhook events than the status on the transfer object of the original synchroneous response
// they are the same but prefixed with 'source.' so here we convert that to use the same status as we use for the original transfer object

export function convertMoovEventToACHPaymentSourceStatus(
  status: string,
): AchLifecycleStatusEnum | null {
  switch (status) {
    case MoovWebhookEventACHSourceStatus.SOURCE_INITIATED:
      return AchLifecycleStatusEnum.INITIATED;
    case MoovWebhookEventACHSourceStatus.SOURCE_ORIGINATED:
      return AchLifecycleStatusEnum.ORIGINATED;
    case MoovWebhookEventACHSourceStatus.SOURCE_CORRECTED:
      return AchLifecycleStatusEnum.CORRECTED;
    case MoovWebhookEventACHSourceStatus.SOURCE_COMPLETED:
      return AchLifecycleStatusEnum.COMPLETED;
    case MoovWebhookEventACHSourceStatus.SOURCE_RETURNED:
      return AchLifecycleStatusEnum.RETURNED;
    default:
      return null;
  }
}

export enum CancellationStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
}

export const CancellationStatusEnum = z.nativeEnum(CancellationStatus);
export type CancellationStatusEnum = z.infer<typeof CancellationStatusEnum>;

export const CardTransferReversalSchema = z.object({
  cancellation: z
    .object({
      createdOn: z.string().optional(),
      status: CancellationStatusEnum.optional(),
    })
    .optional(),
  refund: RefundSchema.optional(),
});
export type CardTransferReversalSchema = z.infer<
  typeof CardTransferReversalSchema
>;
