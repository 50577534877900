import type { Booking, BookingExpense, BookingPayment } from '@prisma/client';
import Decimal from 'decimal.js';
import { PayingEntity } from 'dtos';

export const getBookingMaxRefundAmount = (
  booking: Booking & {
    expenses: (BookingExpense & {
      payments: BookingPayment[];
    })[];
  },
) =>
  booking.payingEntity === PayingEntity.AGENCY
    ? booking.expenses
        .filter((e) => !e.deletedAt)
        .reduce((acc, expense) => {
          const totalPaid = expense.payments
            .filter((p) => !p.voidedAt && !p.deletedAt)
            .reduce((acc, payment) => {
              return acc.plus(payment.amountHome);
            }, new Decimal(0));
          return acc.plus(totalPaid);
        }, new Decimal(0))
    : booking.baseTotalHome ?? booking.totalHome;
