import { Box } from '@mui/material';
import { keyframes } from '@mui/system';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: 'rgb(0, 0, 0, 0.01)',
        // backdropFilter: 'blur(10px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 99999999,
      }}
    >
      <Box
        sx={{
          position: 'fixed',
          top: 80,
          right: 25,
          border: '2px solid #f3f3f3',
          borderRadius: '50%',
          borderTop: '2px solid #505050',
          width: 20,
          height: 20,
          animation: `${spin} 1s linear infinite`,
        }}
      />
    </Box>
  );
};

export default Loader;
