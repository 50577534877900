import z from 'zod';

export enum PaymentStatus {
  CREATED = 'created',
  PENDING = 'pending',
  COMPLETED = 'completed',
  FAILED = 'failed',
  REVERSED = 'reversed',
  QUEUED = 'queued',
  CANCELED = 'canceled',
  // these statuses represent ConnexPay's status for a payment
  APPROVED = 'approved',
  DECLINED = 'declined',
  PARTIALLY_REVERSED = 'partially_reversed',
}
// This is a subset of PaymentStatusEnum and signifies that the payment is Completed or Approved by the payment processor
// and no further updates are expected. It combines the statuses of several processors that mean the same thing
export enum PaymentApprovedStatus {
  APPROVED = 'approved',
  COMPLETED = 'completed',
}

// This is a subset of PaymentStatusEnum and signifies that the payment is Pending and further updates are expected
export enum PaymentPendingStatus {
  CREATED = 'created',
  PENDING = 'pending',
  QUEUED = 'queued',
}

export const PaymentStatusEnum = z.nativeEnum(PaymentStatus);
export type PaymentStatusEnum = z.infer<typeof PaymentStatusEnum>;

export const PaymentApprovedStatusEnum = z.nativeEnum(PaymentApprovedStatus);
export type PaymentApprovedStatusEnum = z.infer<
  typeof PaymentApprovedStatusEnum
>;

export const PaymentPendingStatusEnum = z.nativeEnum(PaymentPendingStatus);
export type PaymentPendingStatusEnum = z.infer<typeof PaymentPendingStatusEnum>;
