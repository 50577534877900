import { Box } from '@mui/material';
import type React from 'react';
import type { ReactNode } from 'react';

type PageContentProps = {
  children: ReactNode;
  noPadding: boolean;
};
const PageContent: React.FC<PageContentProps> = ({
  children,
  noPadding = false,
}) => {
  return (
    <Box
      sx={{
        mt: {
          xs: '48px',
          md: '64px',
          flex: 1,
        },
        px: noPadding ? 0 : 3,
        py: noPadding ? 0 : 3,
        height: {
          xs: 'calc(100vh - 48px)',
          md: 'calc(100vh - 64px)',
        },
      }}
    >
      <Box sx={{ height: '100%' }}>{children}</Box>
    </Box>
  );
};

export default PageContent;
