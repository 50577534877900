import type { BookingExpenseResponseDto } from 'dtos';
import useSWR from 'swr';

import type { PaginatedData, SortModel } from '../types/paginatedData';
import { fetcher } from '../utils/client/swr/jsonFetcher';

export type UseBookingExpensesProps = {
  query?: string;
  page?: number;
  pageSize?: number;
  sortModel?: SortModel;
  bookingId?: string;
};

export default function useBookingExpenses({
  query = '',
  page = 0,
  pageSize = 1000,
  sortModel: { sort, sortDirection } = {
    sort: 'createdAt',
    sortDirection: 'desc',
  },
  bookingId,
}: UseBookingExpensesProps) {
  const params = new URLSearchParams();

  params.set('query', query);
  params.set('page', page.toString());
  params.set('pageSize', pageSize.toString());
  params.set('sort', sort);
  params.set('sortDirection', sortDirection);

  const {
    data,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<PaginatedData<BookingExpenseResponseDto>, Error>(
    `bookings/${bookingId}/expenses?${params.toString()}`,
    bookingId ? fetcher : null,
    {
      revalidateOnFocus: false,
    },
  );

  const { data: expenses = [], meta } =
    (data as PaginatedData<BookingExpenseResponseDto>) || {};

  return { expenses, meta, isLoading, error, refresh: mutate };
}
