import type { Attachment } from '@prisma/client';
import { z } from 'zod';

export const AttachmentResponseDto = z.object({
  id: z.string(),
  name: z.string(),
  externalFilename: z.string(),
});
export type AttachmentResponseDto = z.infer<typeof AttachmentResponseDto>;

export type AllowedExtensions =
  | '.png'
  | '.jpg'
  | '.jpeg'
  | '.pdf'
  | '.svg'
  | '.csv'
  | '.xls'
  | '.xlsx'
  | '.doc'
  | '.docx';

export type FileWithLoadingState = {
  status: 'loading';
  file: File;
  name: string;
};

export type FileWithPendingState = {
  status: 'pending';
  file: File;
  name: string;
};

export type FileWithSuccessState = {
  status: 'success';
  file: File;
  name: string;
  id: string;
};

export type FileWithErrorState = {
  status: 'error';
  name: string;
  file: File;
  message: string;
};

export type FileWithStatus =
  | FileWithLoadingState
  | FileWithSuccessState
  | FileWithErrorState
  | FileWithPendingState;

export enum EntityType {
  TRIP = 'trip',
  BOOKING = 'booking',
  CLIENT = 'client',
  SUPPLIER = 'supplier',
}

export const UploadAttachmentRequestDto = z.object({
  name: z.string(),
  externalFilename: z.string(),
  entityType: z.nativeEnum(EntityType),
  entityId: z.string(),
});
export type UploadAttachmentRequestDto = z.infer<
  typeof UploadAttachmentRequestDto
>;

export const UploadAttachmentResponseDto = z.object({
  id: z.string(),
  name: z.string(),
  externalFilename: z.string(),
});
export type UploadAttachmentResponseDto = z.infer<
  typeof UploadAttachmentResponseDto
>;

export const UploadAttachmentUpdateRequestDto = z.object({
  name: z.string(),
});
export type UploadAttachmentUpdateRequestDto = z.infer<
  typeof UploadAttachmentUpdateRequestDto
>;

export const UploadAttachmentDeleteRequestDto = z.object({
  id: z.string(),
});
export type UploadAttachmentDeleteRequestDto = z.infer<
  typeof UploadAttachmentDeleteRequestDto
>;

export const DownloadRequestDto = z.object({
  externalFilename: z.string(),
});
export type DownloadRequestDto = z.infer<typeof DownloadRequestDto>;

export const DownloadResponseDto = z.object({
  signedUrl: z.string(),
});
export type DownloadResponseDto = z.infer<typeof DownloadResponseDto>;

export const attachmentToDto = (
  attachment: Attachment,
): UploadAttachmentResponseDto => {
  return {
    id: attachment.id,
    name: attachment.name,
    externalFilename: attachment.externalFilename,
  };
};
