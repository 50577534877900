import type { TripReportResponseDto } from 'dtos';
import useSWR from 'swr';

import { fetcher } from '../utils/client/swr/jsonFetcher';

export default function useTripReport(
  tripId: string,
  excludeAirfare?: boolean,
  fallbackReport?: TripReportResponseDto,
) {
  const queryParams = new URLSearchParams();

  excludeAirfare !== undefined &&
    queryParams.set('excludeAirfare', excludeAirfare.toString());

  const {
    data,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<TripReportResponseDto, Error>(
    `/trips/${tripId}/report?${queryParams.toString()}`,
    fetcher,
    {
      fallbackData: fallbackReport,
      revalidateOnFocus: false,
    },
  );

  return { report: data, isLoading, error, refresh: mutate };
}
