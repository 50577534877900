import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box } from '@mui/material';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import MuiDrawer, {
  type DrawerProps as MuiDrawerProps,
} from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import {
  type CSSObject,
  type Theme,
  styled,
  useTheme,
} from '@mui/material/styles';
import UserInfoContext from 'context/user-info.context';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { type Dispatch, type SetStateAction, useContext } from 'react';

import { pages } from './pages';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const StyledDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

interface DrawerProps extends MuiDrawerProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const selectedStyles = (theme: Theme) => ({
  backgroundColor: 'accent.purple.light',
  borderLeftColor: 'accent.purple.dark',
  '& .MuiSvgIcon-root': {
    fill: theme.palette.accent.purple.dark,
  },
});

export default function Drawer({ open, setOpen }: DrawerProps) {
  const theme = useTheme();
  const router = useRouter();
  const userInfo = useContext(UserInfoContext);

  return (
    <StyledDrawer variant="permanent" open={open}>
      <DrawerHeader>
        <Box
          sx={{
            ml: 2,
            mr: 'auto',
            height: '100%',
            alignItems: 'center',
            display: open ? 'flex' : 'none',
          }}
        >
          <Image src={'/logo.svg'} alt="TripSuite" width={115} height={24} />
        </Box>
        <IconButton
          onClick={() => setOpen(false)}
          sx={{ display: open ? 'flex' : 'none' }}
        >
          {theme.direction === 'rtl' ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List sx={{ pt: 0 }}>
        {pages
          .filter(
            (c) =>
              !c.shouldShow ||
              (c.shouldShow && userInfo && c.shouldShow(userInfo)),
          )
          .map(({ name, path, IconComponent: Icon, beta }) => {
            const selected =
              router.pathname === path ||
              (path.length > 1 && router.pathname.startsWith(path));

            return (
              <ListItem
                key={name}
                disablePadding
                sx={(theme) => ({
                  display: 'block',
                  borderLeft: '2px solid transparent',
                  borderRight: '2px solid transparent',
                  ...(selected ? selectedStyles(theme) : {}),
                  '&:hover': {
                    ...selectedStyles(theme),
                    ...(!selected ? { borderLeftColor: 'transparent' } : {}),
                  },
                })}
              >
                <Link href={path} legacyBehavior>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <Icon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                        color: 'text.secondary',
                      }}
                    />
                    <ListItemText
                      primary={name}
                      sx={{
                        opacity: open ? 1 : 0,
                        '& .MuiTypography-root': {
                          fontFamily: 'Poppins',
                          fontSize: 16,
                          fontWeight: 500,
                        },
                      }}
                    />
                    {beta && <Chip label="Beta" size="small" color="primary" />}
                  </ListItemButton>
                </Link>
              </ListItem>
            );
          })}
      </List>
    </StyledDrawer>
  );
}
