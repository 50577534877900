import type { LeadStageType, TripResponseDto } from 'dtos';
import useSWR from 'swr';
import { getUrlSearchParams } from 'utils/client/fetch';
import type { PaginatedData, SortModel } from '../types/paginatedData';
import { fetcher } from '../utils/client/swr/jsonFetcher';

type TripFilters = {
  status: LeadStageType[];
  leadStages: number[];
  minDate: string;
  maxDate: string;
  daysToSearch?: number;
};

type UseTripsProps = {
  query?: string;
  clientId?: string;
  corporateClientId?: string;
  page?: number;
  pageSize?: number;
  sortModel: SortModel;
  filters?: TripFilters;
  getAll?: boolean;
  agencyUserId?: string;
};

export default function useTrips({
  query = '',
  clientId,
  corporateClientId,
  page = 0,
  pageSize = 20,
  sortModel: { sort, sortDirection },
  filters,
  getAll = false,
  agencyUserId,
}: UseTripsProps) {
  const status = filters?.status || [];
  const leadStages = filters?.leadStages || [];
  const minDate = filters?.minDate || undefined;
  const maxDate = filters?.maxDate || undefined;
  const daysToSearch = filters?.daysToSearch || undefined;

  const statusParams = status?.length
    ? status.map((status) => `&status=${status}`).join('')
    : '';

  const leadStagesParams = leadStages?.length
    ? leadStages.map((leadStage) => `&leadStage=${leadStage}`).join('')
    : '';

  const queryParams = getUrlSearchParams({
    query,
    page: getAll ? '0' : page.toString(),
    pageSize: getAll ? '1000000' : pageSize.toString(),
    sort,
    sortDirection,
    clientId,
    corporateClientId,
    minDate,
    maxDate,
    daysToSearch,
    agencyUserId,
  });

  const endpoint = `trips?${queryParams.toString()}${statusParams}${leadStagesParams}`;

  const {
    data: response,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<PaginatedData<TripResponseDto>, Error>(endpoint, fetcher, {
    revalidateOnFocus: false,
  });

  const { data, meta } = (response as PaginatedData<TripResponseDto>) || {};

  return { data, meta, isLoading, error, refresh: mutate };
}
