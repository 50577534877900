import type { LeadAssignmentFilter, LeadResponseDto } from 'dtos';
import useSWR from 'swr';

import type { PaginatedData, SortModel } from '../types/paginatedData';
import { fetcher } from '../utils/client/swr/jsonFetcher';

export type UseLeadsProps = {
  query?: string;
  page?: number;
  pageSize?: number;
  sortModel: SortModel;
  stageFilterId?: string;
  assigneeFilter?: LeadAssignmentFilter;
};

export default function useLeads({
  query = '',
  page = 1,
  pageSize = 20,
  sortModel: { sort, sortDirection },
  stageFilterId,
  assigneeFilter,
}: UseLeadsProps) {
  const params = new URLSearchParams();
  params.set('query', query);
  params.set('page', page.toString());
  params.set('pageSize', pageSize.toString());
  params.set('sort', sort);
  params.set('sortDirection', sortDirection);
  stageFilterId && params.set('stageFilter', stageFilterId);
  assigneeFilter && params.set('assigneeFilter', assigneeFilter);
  const key = `leads?${params.toString()}`;

  const {
    data,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<PaginatedData<LeadResponseDto>, Error>(key, fetcher, {
    revalidateOnFocus: false,
  });

  const { data: leads = [], meta } =
    (data as PaginatedData<LeadResponseDto>) || {};

  return { leads, meta, isLoading, error, refresh: mutate };
}
