import { getCookie } from 'cookies-next';
import fetch from 'isomorphic-fetch';

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
type MockResponseData = any;

export async function fetcher<T>(
  url: string | MockResponseData,
  init?: RequestInit,
  apiHost?: string,
): Promise<T> {
  let contextToken;
  try {
    const { token } = JSON.parse(getCookie('context')?.toString() || '{}');
    contextToken = token;
  } catch (error) {
    console.error(error);
  }

  const options = {
    ...(init || {}),
    headers: {
      ...(init?.headers || {}),
      ...(contextToken ? { 'x-ts-context': contextToken } : {}),
    },
  };

  if (typeof url === 'object') return Promise.resolve(url);

  const res = await fetch(
    `${apiHost ?? process.env.API_HOST ?? ''}/api${
      url.startsWith('/') ? '' : '/'
    }${url}`,
    options,
  );
  return res.json();
}

export function getFetcherWithCustomHost<T>(
  apiHost: string,
): (url: string, init?: RequestInit) => Promise<T> {
  return async (url: string, init?: RequestInit) =>
    fetcher<T>(url, init, apiHost);
}
