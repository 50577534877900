import { Box, type SxProps, type Theme } from '@mui/material';

import ContextPickerBadge from './ContextPickerBadge';

type ContextAgencyAvatarProps = {
  agencyInitials: string;
  userInitials: string;
  color: string;
  sx?: SxProps<Theme>;
};

export default function ContextAgencyAvatar({
  agencyInitials,
  userInitials,
  color,
  sx = {},
}: ContextAgencyAvatarProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
        width: 40,
        minWidth: 40,
        borderRadius: 1,
        backgroundColor: color || '#eee',
        color: '#fff',
        ...sx,
      }}
    >
      {agencyInitials}
      {userInitials && (
        <ContextPickerBadge
          color={color}
          text={userInitials}
          sx={{
            bottom: -10,
            right: -10,
          }}
        />
      )}
    </Box>
  );
}
