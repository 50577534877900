export const getUrlSearchParams = (
  params: Record<string, boolean | number | string | undefined | null>,
): URLSearchParams => {
  const queryParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      queryParams.set(key, String(value));
    }
  });

  return queryParams;
};
