// cspell:disable
import { Payment } from '@mui/icons-material';
import type { PaymentReversal, PaymentTransaction } from '@prisma/client';
import Decimal from 'decimal.js';
import type { PaymentTransactionResponseDto } from 'dtos';
import {
  // CardPaymentStatus,
  MoneySchema,
  // RefundStatus,
  MoovRefundStatus,
} from 'dtos/moov.dtos';
import {
  PaymentApprovedStatus,
  PaymentApprovedStatusEnum,
  PaymentPendingStatus,
  PaymentStatus,
  PaymentStatusEnum,
} from 'dtos/paymentProcessor-shared.dtos';
import { isValueInEnum } from 'utils/server/enumUtil';
import z from 'zod';

export const WebhookRefundSchema = z.object({
  accountID: z.string().uuid(),
  transferID: z.string().uuid(),
  refundID: z.string().uuid(),
  status: MoovRefundStatus.optional(),
});
export type WebhookRefundSchema = z.infer<typeof WebhookRefundSchema>;

// RefundStatus is specific to
export enum RefundStatus {
  VOID_INITIATED = 'void.initiated',
  VOID_COMPLETE = 'void.complete',
  VOID_DECLINED = 'void.declined',
  CANCEL_INITIATED = 'cancel.initiated',
  CANCEL_COMPLETE = 'cancel.complete',
  CANCEL_DECLINED = 'cancel.declined',
  REFUND_INITIATED = 'refund.initiated',
  REFUND_COMPLETE = 'refund.complete',
  REFUND_DECLINED = 'refund.declined',
}

export const isSuccessCardPaymentStatusSyncResp = (
  status: string | null,
): boolean => {
  if (status === null) {
    return false;
  }
  return (
    status === CardPaymentStatus.CONFIRMED ||
    status === CardPaymentStatus.SETTLED ||
    status === CardPaymentStatus.COMPLETED ||
    status === CardPaymentStatus.INITIATED
  );
};

export const isPaymentSuccessStatus = (
  overallStatus: string | null,
): boolean => {
  if (overallStatus === null) {
    return false;
  }
  return isValueInEnum(
    PaymentApprovedStatus,
    overallStatus as PaymentApprovedStatus,
  );
};

export const isCompletedTransferAndFullyPaid = (
  paymentTransaction?:
    | (PaymentTransaction & {
        paymentReversals: PaymentReversal[];
      })
    | null,
): boolean => {
  if (!paymentTransaction) {
    return false;
  }
  return (
    isValueInEnum(
      PaymentApprovedStatus,
      paymentTransaction.overallStatus as PaymentApprovedStatus,
    ) &&
    // if there are no paymentReversals, we can assume it was completely paid if status is 'completed
    (paymentTransaction.paymentReversals?.length === 0 ?? true)
  );
};

// A payment is considered pending if it's in any of these statuses
// it will be marked as COMPLETE when truly complete (settled or confirmed)
export const isLikePendingStatus = (overallStatus?: string): boolean => {
  if (!overallStatus) {
    return false;
  }
  return isValueInEnum(
    PaymentPendingStatus,
    overallStatus as PaymentPendingStatus,
  );
};

// A refund can be for a ConnexPay Refund
export const paymentHasPendingRefund = (
  paymentTransaction:
    | (PaymentTransaction & {
        paymentReversals: PaymentReversal[];
      })
    | null,
): boolean => {
  if (!paymentTransaction?.paymentReversals) {
    return false;
  }

  return paymentTransaction.paymentReversals.some(
    (reversal) =>
      reversal.type === ReversalType.REFUND &&
      (reversal.refundStatus === RefundStatus.REFUND_INITIATED ||
        reversal.refundStatus === RefundStatus.VOID_INITIATED ||
        reversal.refundStatus === RefundStatus.CANCEL_INITIATED),
  );
};

// We consider it a completed refund if all reversals are complete
// where reversals can be Void, Cancel, or Refund
export const paymentHasCompletedRefund = (
  paymentTransaction:
    | (PaymentTransaction & {
        paymentReversals: PaymentReversal[];
      })
    | null,
): boolean => {
  if (
    !paymentTransaction?.paymentReversals ||
    paymentTransaction?.paymentReversals.length === 0
  ) {
    return false;
  }

  const refundedAmount = paymentTransaction.paymentReversals.reduce(
    (accumulator, reversal) => {
      if (
        reversal.refundStatus === RefundStatus.REFUND_COMPLETE ||
        reversal.refundStatus === RefundStatus.VOID_COMPLETE ||
        reversal.refundStatus === RefundStatus.CANCEL_COMPLETE
      ) {
        return accumulator.add(reversal.amount ?? new Decimal(0));
      }
      return accumulator;
    },
    new Decimal(0),
  );

  if (refundedAmount.greaterThanOrEqualTo(paymentTransaction.amount ?? 0)) {
    return true;
  }

  return false;
};

export const paymentHasPartialRefund = (
  paymentTransaction:
    | (PaymentTransaction & {
        paymentReversals: PaymentReversal[];
      })
    | null,
): boolean => {
  if (
    !paymentTransaction?.paymentReversals ||
    paymentTransaction?.paymentReversals.length === 0
  ) {
    return false;
  }

  const refundedAmount = paymentTransaction.paymentReversals.reduce(
    (accumulator, reversal) => {
      if (
        reversal.refundStatus === RefundStatus.REFUND_COMPLETE ||
        reversal.refundStatus === RefundStatus.VOID_COMPLETE ||
        reversal.refundStatus === RefundStatus.CANCEL_COMPLETE
      ) {
        return accumulator.add(reversal.amount ?? new Decimal(0));
      }
      return accumulator;
    },
    new Decimal(0),
  );
  const paymentTransactionAmount = paymentTransaction.amount ?? 0;

  if (
    refundedAmount.greaterThan(0) &&
    refundedAmount.lessThan(paymentTransactionAmount)
  ) {
    return true;
  }

  return false;
};

// We consider here a "cancellation" as a ConnexPay Void or Cancel
export const paymentHasPendingCancellation = (
  paymentTransaction:
    | PaymentTransactionResponseDto
    | (PaymentTransaction & {
        paymentReversals: PaymentReversal[];
      })
    | null,
): boolean => {
  if (!paymentTransaction?.paymentReversals) {
    return false;
  }

  return paymentTransaction.paymentReversals.some(
    (reversal) =>
      (reversal.type === ReversalType.CANCELLATION ||
        reversal.type === ReversalType.VOID) &&
      (reversal.refundStatus === RefundStatus.CANCEL_INITIATED ||
        reversal.refundStatus === RefundStatus.VOID_INITIATED),
  );
};

export const reversalStatusIsDeclined = (
  paymentReversalStatus: string | null | undefined,
): boolean => {
  if (!paymentReversalStatus) {
    return false;
  }

  return (
    paymentReversalStatus === RefundStatus.VOID_DECLINED ||
    paymentReversalStatus === RefundStatus.CANCEL_DECLINED ||
    paymentReversalStatus === RefundStatus.REFUND_DECLINED
  );
};

// TODO: revisit this
export const isCancelledOrReversed = (
  overallStatus: string | null | undefined,
): boolean => {
  if (overallStatus === null) {
    return false;
  }
  return (
    overallStatus === PaymentStatus.CANCELED ||
    overallStatus === PaymentStatus.REVERSED
  );
};

export const isFailedPayment = (
  overallStatus: string | null | undefined,
): boolean => {
  if (overallStatus === null) {
    return false;
  }
  return (
    overallStatus === PaymentStatus.FAILED ||
    overallStatus === PaymentStatus.DECLINED
  );
};

export function getLastPaymentDate(
  paymentTransactions:
    | (PaymentTransaction & {
        paymentReversals: PaymentReversal[];
      })[]
    | null,
): Date | null {
  if (!paymentTransactions || paymentTransactions.length === 0) {
    return null;
  }

  const latestDate = paymentTransactions.reduce(
    (accumulator: Date | null, currentTransaction) => {
      if (currentTransaction.overallStatus === PaymentStatus.COMPLETED) {
        const currentDate = new Date(currentTransaction.updatedAt);
        if (!accumulator) return currentDate;
        return currentDate > accumulator ? currentDate : accumulator;
      }
      return accumulator;
    },
    null,
  );

  return latestDate;
}

export enum ReversalType {
  VOID = 'void',
  CANCELLATION = 'cancellation',
  REFUND = 'refund',
}
export const ReversalTypeEnum = z.nativeEnum(ReversalType);
export type ReversalTypeEnum = z.infer<typeof ReversalTypeEnum>;

export const PaymentReversalResponseDto = z.object({
  id: z.string().uuid(),
  createdAt: z.string().optional(),
  updatedAt: z.string().optional(),
  paymentTransactionId: z.string().uuid(),
  clientInvoiceRefundId: z.string().uuid().optional(),
  partnerTransactionID: z.string().optional(),
  partnerRefundID: z.string().optional(),
  partnerCreatedOn: z.date().optional(),
  partnerUpdatedOn: z.date().optional(),
  amount: MoneySchema.optional(),
  type: ReversalTypeEnum,
  cancelledStatus: z.string().optional(),
  refundStatus: z.string().optional(),
  refundCardDetailsCompletedAt: z.date().optional(),
  refundCardDetailsConfirmedAt: z.date().optional(),
  refundCarDetailsFailedAt: z.date().optional(),
  refundCardDetailsInitiatedAt: z.date().optional(),
  refundCardDetailsSettledAt: z.date().optional(),
  refundCardDetailsStatus: z.string().optional(),
  refundCardDetailsFailureCode: z.string().optional(),
});
export type PaymentReversalResponseDto = z.infer<
  typeof PaymentReversalResponseDto
>;

export enum CardPaymentStatus {
  INITIATED = 'initiated',
  CONFIRMED = 'confirmed',
  CANCELED = 'canceled',
  FAILED = 'failed',
  SETTLED = 'settled',
  COMPLETED = 'completed',
}

export const CardPaymentStatusEnum = z.nativeEnum(CardPaymentStatus);
export type CardPaymentStatusEnum = z.infer<typeof CardPaymentStatusEnum>;

export const ProcessPaymentPendingResponseDto = z.object({
  paymentTransactionId: z.string().uuid().optional(),
  transferId: z.string().uuid().optional(),
  message: z.string(),
  type: z.literal('warning'),
});
export type ProcessPaymentPendingResponseDto = z.infer<
  typeof ProcessPaymentPendingResponseDto
>;

export const ProcessPaymentFailResponseDto = z.object({
  paymentTransactionId: z.string().uuid().optional(),
  transferId: z.string().uuid().optional(),
  status: PaymentStatusEnum.optional(),
  sourceStatus: CardPaymentStatusEnum.optional(),
  message: z.string(),
  type: z.literal('error'),
});
export type ProcessPaymentFailResponseDto = z.infer<
  typeof ProcessPaymentFailResponseDto
>;
