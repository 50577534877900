import type { AgencyResponseDto } from 'dtos';
import useSWR from 'swr';
import type { PaginatedData, SortModel } from 'types/paginatedData';
import { fetcher } from 'utils/client/swr/jsonFetcher';

type UseUserAgenciesProps = {
  query?: string;
  page?: number;
  pageSize?: number;
  getAll?: true;
  sortModel: SortModel;
  userId?: string;
};

export default function useUserAgencies({
  query = '',
  page = 0,
  pageSize = 20,
  getAll,
  sortModel: { sort, sortDirection },
  userId,
}: UseUserAgenciesProps) {
  const {
    data,
    isValidating: isLoading,
    error,
  } = useSWR<PaginatedData<AgencyResponseDto>, Error>(
    `users/${userId ?? 'me'}/agencies?query=${query}&page=${page}&pageSize=${
      getAll ? 1000000 : pageSize
    }&sort=${sort}&sortDirection=${sortDirection}`,
    fetcher,
    {
      revalidateOnFocus: false,
    },
  );

  const { data: agencies = [], meta } =
    (data as PaginatedData<AgencyResponseDto>) || {};

  return { agencies, meta, isLoading, error };
}
