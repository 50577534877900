import { useClerk } from '@clerk/clerk-react';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Divider, IconButton, Stack, useTheme } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import ProfileMenuDropdown from 'components/common/ProfileMenuDropdown';
import ContextPicker from 'components/common/contextPicker/ContextPicker';
import type { AppBarProps, Context } from 'components/types';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import useHierarchicalAgencies from 'hooks/useHierarchicalAgencies';
import useUserProfile from 'hooks/useUserProfile';
import LogRocket from 'logrocket';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { getContextToken } from 'requests/context';

import { getContextFromCookie } from 'utils/client/contextTree';
import StyledAppBar from './StyledAppBar';

export default function AppBar({ open, setOpen }: AppBarProps) {
  const theme = useTheme();
  const router = useRouter();
  const {
    agencies,
    defaultCtx,
    isLoading: isContextPickerLoading,
  } = useHierarchicalAgencies();

  const [context, setContext] = useState<Context | null>(null);
  const { userProfile } = useUserProfile();
  const { signOut } = useClerk();

  useEffect(() => {
    if (userProfile) {
      LogRocket.identify(userProfile.id, {
        name: `${userProfile.firstName} ${userProfile.lastName}`,
        email: userProfile.email,
      });
    }
  }, [userProfile]);

  useEffect(() => {
    if (!getContextFromCookie() && defaultCtx) {
      setCookie('context', defaultCtx);
      setContext(defaultCtx);
    }
  }, [!!defaultCtx]);

  const setContextValue = async (c: Context | null, reloadApp = false) => {
    if (c) {
      const { token } = await getContextToken({
        agencyId: c.agencyId,
        userId: c.userId,
      });
      setCookie('context', { ...c, token });
    } else {
      deleteCookie('context');
    }

    if (reloadApp) {
      router.reload();
    } else {
      setContext(c);
    }

    return null;
  };

  useEffect(() => {
    if (!isContextPickerLoading) {
      const contextCookie = getCookie('context');
      let savedContextIsInvalid = true;
      if (contextCookie && agencies?.length) {
        const c = JSON.parse(contextCookie as string);
        const agency = agencies.find((a) => a.agency.id === c.agencyId);
        const userExists =
          !c.userId || agency?.users.find((u) => u.id === c.userId);
        savedContextIsInvalid = !agency || !userExists;
        if (!savedContextIsInvalid) {
          //saved cookie contains valid values of agencyId and userId
          void setContextValue(c);
        }
      }

      if (savedContextIsInvalid) {
        //saved cookie contains invalid values of agencyId and userId
        void setContextValue(null);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencies, isContextPickerLoading]);

  function handleSignOut() {
    void signOut();
    deleteCookie('USER_INFO_COOKIE');
  }

  const showContextPicker =
    !isContextPickerLoading &&
    Boolean(agencies?.length) &&
    (agencies.length > 1 || agencies[0].users?.length > 1);

  return (
    <StyledAppBar
      position="fixed"
      open={open}
      sx={{
        '&.MuiAppBar-root': () => ({
          backgroundColor: theme.palette.background.default,
          boxShadow: '0px 0px 0px 1px #E0E0E0',
          color: 'text.primary',
        }),
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          pl: 3, // after disabling the gutters this adds back in the left gutter.
          justifyContent: 'space-between',
        }}
      >
        <Stack flexDirection={'row'} alignItems={'center'}>
          <IconButton
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            edge="start"
            sx={{
              color: 'text.secondary',
              mr: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box id="page-title" />
        </Stack>

        <Stack flexDirection={'row'} alignItems={'center'}>
          <Box id="page-actions" sx={{ mr: 2 }} />
          {showContextPicker ? (
            <>
              <Divider orientation="vertical" flexItem />
              <ContextPicker
                agencies={agencies}
                value={context}
                setValue={(value) => setContextValue(value, true)}
              />
            </>
          ) : null}
          <Divider orientation="vertical" flexItem />

          <ProfileMenuDropdown
            {...userProfile}
            signOut={handleSignOut}
            handleGoToSettings={() => void router.push('/settings')}
          />
        </Stack>
      </Toolbar>
    </StyledAppBar>
  );
}
