import type { SearchableListItem } from 'components/types';
import useSWR from 'swr';

import type { DestinationResponseListDto } from 'dtos';
import { fetcher } from '../utils/client/swr/jsonFetcher';

export default function useDestinations() {
  const {
    data,
    isValidating: isLoading,
    error,
    mutate: refresh,
  } = useSWR<DestinationResponseListDto, Error>(
    'destinations?pageSize=1000000',
    fetcher,
    {
      revalidateOnFocus: false,
    },
  );

  return {
    destinations: data?.data?.map((d) => ({
      id: d.id,
      name: d.name,
      searchableName: d.name.normalize('NFD').replace(/\p{Diacritic}/gu, ''),
    })) as SearchableListItem[],
    isLoading,
    error,
    refresh,
  };
}
