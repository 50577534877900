import {
  blue,
  deepPurple,
  green,
  grey,
  lightBlue,
  orange,
  pink,
  purple,
  red,
} from '@mui/material/colors';
import { createTheme, lighten } from '@mui/material/styles';
import { gridClasses } from '@mui/x-data-grid-pro';

export default createTheme({
  typography: { fontFamily: 'Roboto, sans-serif' },
  palette: {
    success: { main: '#2e7d32', light: 'rgba(76, 175, 80, 1)' },
    error: { main: red[500] },
    mode: 'light',
    primary: { main: deepPurple[500] },
    warning: { main: orange[600], contrastText: '#FFF' },
    black: { main: grey[800], contrastText: '#FFF' },
    action: { active: 'rgba(0, 0, 0, 0.54)' },
    secondary: {
      main: grey[300],
      light: lighten(grey[300], 0.5),
      contrastText: 'rgba(255,255,255,.7)',
    },
    text: {
      primary: '#000',
      secondary: 'rgba(0, 0, 0, 0.54)',
      contrast: '#FFF',
    },
    accent: {
      purple: {
        light: deepPurple[50],
        dark: deepPurple['A400'],
      },
      green: { main: green[800] },
      blue: { main: lightBlue[700] },
      orange: { main: orange[600] },
      red: { main: red[700] },
      gray: { main: 'rgba(0, 0, 0, 0.08)' },
    },
    background: {
      main: '#fff',
      secondary: 'rgba(0, 0, 0, 0.04)',
      light: 'rgba(0, 0, 0, 0.08)',
      lighter: '#eee',
      lightest: '#FAFAFA',
      success:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2E7D32',
      error:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #D32F2F;',
    },
    charting: {
      purple: { main: deepPurple[500], light: purple['A400'] },
      orange: { main: orange['A400'] },
      pink: { main: pink['A400'], light: pink[300] },
      gray: { light: 'rgba(0, 0, 0, 0.12)' },
    },
    chips: {
      default: grey[300],
      error: red[500],
      info: blue[500],
      primary: deepPurple[500],
      success: green[700],
      warning: orange[600],
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.MuiButton-containedSecondary:hover': {
            backgroundColor: theme.palette.secondary.light,
          },
        }),
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 0,
          [`& .${gridClasses.cell}:focus`]: {
            outline: 'none',
          },
          [`& .${gridClasses.cell}:focus-within`]: {
            outline: 'none',
          },
        },
        row: ({ theme }) => ({
          '&:hover': {
            cursor: 'pointer',
          },
          '&.highlight-row-orange': {
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
              width: '2px',
              backgroundColor: theme.palette.warning.main,
            },
          },
          '&.highlight-row-green': {
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
              width: '2px',
              backgroundColor: theme.palette.success.main,
            },
          },
        }),
        cell: {
          '&:active, :focus': { outline: 'none !important' },
        },
        columnHeader: {
          '&:focus': { outline: 'none !important' },
        },
        footerContainer: {
          paddingRight: '40px',
        },
      },
    },
  },
});
