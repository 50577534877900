import useSWR from 'swr';

import type { SupplierYearlyStatsDto } from 'dtos';
import type {
  PaginatedData,
  PaginatedDataResponse,
  SortModel,
} from '../types/paginatedData';
import { fetcher } from '../utils/client/swr/jsonFetcher';

type UseSuppliersReportingYearlyStatsProps = {
  supplierId: string;
  page?: number;
  pageSize?: number;
  getAll?: true;
  sortModel: SortModel;
};

export default function useSupplierReportingYearlyStats({
  supplierId,
  page = 0,
  pageSize = 20,
  getAll,
  sortModel: { sort, sortDirection },
}: UseSuppliersReportingYearlyStatsProps): PaginatedDataResponse<SupplierYearlyStatsDto> {
  const key = `suppliers/${supplierId}/reporting/yearly-stats?page=${page}&pageSize=${
    getAll ? 1000000 : pageSize
  }&sort=${sort}&sortDirection=${sortDirection}`;

  const {
    data: response,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<PaginatedData<SupplierYearlyStatsDto>, Error>(key, fetcher, {
    revalidateOnFocus: false,
  });

  const { data, meta } =
    (response as PaginatedData<SupplierYearlyStatsDto>) || {};

  return {
    data,
    meta,
    isLoading,
    error,
    refresh: () => void mutate(),
  };
}
