import type { BookingExpenseResponseDto } from 'dtos';
import useSWR from 'swr';

import { fetcher } from '../utils/client/swr/jsonFetcher';

export default function useBookingExpense(
  bookingExpenseId: string | null | undefined,
  bookingId: string | null | undefined,
) {
  const key = `bookings/${bookingId}/expenses/${bookingExpenseId}`;

  const {
    data,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<BookingExpenseResponseDto>(
    key,
    (bookingExpenseId && bookingId && fetcher) || null,
    {
      revalidateOnFocus: false,
    },
  );

  const bookingExpense = data as BookingExpenseResponseDto;

  return { bookingExpense, isLoading, error, refresh: mutate };
}
