import type { ProfileWithAgencyNameResponseDto } from 'dtos';
import useHierarchicalAgencies from './useHierarchicalAgencies';

export type UseAdvisorParams = {
  uniqueAdvisorsOnly?: boolean;
};

export default function useAdvisors({
  uniqueAdvisorsOnly = true,
}: UseAdvisorParams = {}) {
  const { agencies, ...params } = useHierarchicalAgencies();

  const advisors: ProfileWithAgencyNameResponseDto[] = (agencies || []).reduce(
    (prev, current) => [
      ...prev,
      ...current.users.map((u) => ({ ...u, agencyName: current.agency.name })),
    ],
    [] as ProfileWithAgencyNameResponseDto[],
  );

  const uniqueAdvisors = uniqueAdvisorsOnly
    ? advisors.reduce((prev, current) => {
        if (prev.find((item) => item.id === current.id)) return prev;

        return [...prev, current];
      }, [] as ProfileWithAgencyNameResponseDto[])
    : advisors;

  return {
    data: uniqueAdvisors,
    ...params,
  };
}
